import React, { useEffect, useMemo } from 'react'
import { browserName, osName } from 'react-device-detect'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useGetPublicCodeQuery, useSendEmailSendgridScanCodeMutation } from '../../api/api'
import useTrackUserBehavior from '../../hooks/useTrackUserBehavior'
import { LANGUAGE_COUNTRY_CODES } from '../../helpers/constants'

import PdfPreview from '../../components/PreviewForms/PdfPreview'
import Mp3Preview from '../../components/PreviewForms/Mp3Preview'
import VideoPreview from '../../components/PreviewForms/VideoPreview'
import ImagePreview from '../../components/PreviewForms/ImagePreview'
import WifiPreview from '../../components/PreviewForms/WifiPreview'
import TextPreview from '../../components/PreviewForms/TextPreview'

const CodePage = () => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()

    const { data, isError } = useGetPublicCodeQuery(id, { skip: !id })
    const [scanCode, { isSuccess }] = useSendEmailSendgridScanCodeMutation()

    useTrackUserBehavior(data)

    const handleNavigateBack = () => {
        navigate(`/dashboard?from_preview=${id}`)
    }

    const content = useMemo(() => {
        switch (data?.data?.kind) {
            case 'website_url': {
                if (isSuccess || searchParams.get('preview') === 'true') {
                    if (data?.data?.content?.website_url !== 'https://') {
                        window.location.href = data?.data?.content?.website_url
                    }
                }
                return ''
            }
            case 'pdf': {
                return (
                    <PdfPreview
                        data={{
                            designPrimaryColor: data?.data?.content?.primary_color,
                            designSecondaryColor: data?.data?.content?.secondary_color,
                            designTextColor: data?.data?.content?.text_color,
                            designButtonColor: data?.data?.content?.button_color,
                            logo: data?.data?.additional_images?.logo,
                            company: data?.data?.content?.company_name,
                            title: data?.data?.content?.title,
                            description: data?.data?.content?.description,
                            button: data?.data?.content?.button_text,
                            image: data?.data?.additional_images?.cover,
                            website: data?.data?.content?.website,
                            files: data?.data?.files,
                        }}
                    />
                )
            }
            case 'mp3': {
                return (
                    <Mp3Preview
                        data={{
                            designPrimaryColor: data?.data?.content?.primary_color,
                            designSecondaryColor: data?.data?.content?.secondary_color,
                            designTextColor: data?.data?.content?.text_color,
                            designButtonColor: data?.data?.content?.button_color,
                            title: data?.data?.content?.title,
                            description: data?.data?.content?.description,
                            button: data?.data?.content?.button_text,
                            logo: data?.data?.default_image?.image,
                            file: data?.data?.default_file?.file,
                            allowDownload: data?.data?.content?.allowDownload,
                            redirect: data?.data?.content?.button_redirect_link,
                            website: data?.data?.content?.website,
                        }}
                    />
                )
            }
            case 'video': {
                return (
                    <VideoPreview
                        data={{
                            designPrimaryColor: data?.data?.content?.primary_color,
                            designSecondaryColor: data?.data?.content?.secondary_color,
                            designTextColor: data?.data?.content?.text_color,
                            designButtonColor: data?.data?.content?.button_color,
                            logo: data?.data?.additional_images?.logo,
                            company: data?.data?.content?.company_name,
                            title: data?.data?.content?.title,
                            description: data?.data?.content?.description,
                            button: data?.data?.content?.button_text,
                            link: data?.data?.content?.video_url,
                            files: data?.data?.files,
                            website: data?.data?.content?.website,
                            redirect: data?.data?.content?.button_redirect_link,
                        }}
                    />
                )
            }
            case 'images': {
                return (
                    <ImagePreview
                        data={{
                            designPrimaryColor: data?.data?.content?.primary_color,
                            designSecondaryColor: data?.data?.content?.secondary_color,
                            designTextColor: data?.data?.content?.text_color,
                            designButtonColor: data?.data?.content?.button_color,
                            title: data?.data?.content?.title,
                            description: data?.data?.content?.description,
                            button: data?.data?.content?.button_text,
                            website: data?.data?.content?.website_url,
                            redirect: data?.data?.content?.button_redirect_link,
                            files: data?.data?.images,
                        }}
                    />
                )
            }
            case 'wifi': {
                return (
                    <WifiPreview
                        data={{
                            designPrimaryColor: data?.data?.content?.primary_color,
                            designSecondaryColor: data?.data?.content?.secondary_color,
                            designTextColor: data?.data?.content?.text_color,
                            designButtonColor: data?.data?.content?.button_color,
                            type: data?.data?.content?.type,
                            network_name: data?.data?.content?.network_name,
                            password: data?.data?.content?.password,
                            logo: data?.data?.additional_images?.logo,
                            cover: data?.data?.additional_images?.cover,
                        }}
                    />
                )
            }
            case 'text': {
                return (
                    <TextPreview
                        creationPreview={false}
                        data={{
                            designPrimaryColor: data?.data?.content?.primary_color,
                            designSecondaryColor: data?.data?.content?.secondary_color,
                            designTextColor: data?.data?.content?.text_color,
                            designButtonColor: data?.data?.content?.button_color,
                            text: data?.data?.content?.text,
                            logo: data?.data?.additional_images?.logo,
                            subject: data?.data?.content?.subject,
                            company: data?.data?.content?.company_name,
                        }}
                    />
                )
            }
            default: {
                return ''
            }
        }
    }, [data, isSuccess, searchParams])

    useEffect(() => {
        if (searchParams.get('preview') !== 'true') {
            if (isError) {
                navigate(`/code-expired?status=expired&code=${id}`)
            } else if (data) {
                if (data.archived) {
                    navigate('/code-expired?status=archived')
                } else {
                    const language = navigator.language.slice(0, 2)

                    scanCode({
                        data: {
                            browser: browserName,
                            os: osName,
                            language: LANGUAGE_COUNTRY_CODES[language] || 'English'
                        },
                        id: data?.id,
                    })
                }
            }
        }
    }, [scanCode, data, isError, searchParams, navigate, id])

    return (
        <div className='w-full min-h-screen qr-code-scanning'>
            {content}
            {searchParams.get('preview') === 'true' && (
                <button onClick={handleNavigateBack} className='code-page-exit-preview-button'>
                    Exit Preview
                </button>
            )}
        </div>
    )
}

export default CodePage