import React from 'react'
import { useTranslation } from 'react-i18next'

import wifiCopySvg from '../../assets/icons/wifi-cope.svg'

const WifiPreview = ({ data }) => {
    const { t } = useTranslation()

    const handleCopyPassword = (password) => {
        navigator.clipboard.writeText(password)
    }

    return (
        <div className='wifi-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
            <div className='wifi-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor, paddingBottom: `${data.cover ? '160px' : '40px'}` }}>
                <div className='wifi-logo-block'>
                    {data.logo && (
                        <img className='wifi-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                    )}
                </div>
                <span className='wifi-preview-title' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                    {data.network_name ? data.network_name : t("networkName")}
                </span>
                <span className='wifi-preview-network-name' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                    {data.type ? data.type : t("networkType")}
                </span>
            </div>
            <div className='wifi-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                {data.cover && (
                    <div className='wifi-preview-block-img-wrapper-no-shadow'>
                        <img className='wifi-preview-img' src={typeof (data.cover) === 'string' ? data.cover : URL.createObjectURL(data.cover)} alt="" />
                    </div>
                )}
                <div className='wifi-preview-password-block'>
                    <input value={data.password} className='wifi-preview-password-input' placeholder={t("wifiPassword")} readOnly />
                    <button style={{ backgroundColor: data.designButtonColor }} onClick={() => handleCopyPassword(data.password)} className='wifi-preview-cope-btn'>
                        <img src={wifiCopySvg} alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WifiPreview