import React, { useMemo } from 'react'
import { useEditQrCodeContext } from '../../context/EditQrCodeContext'
import WebsitePreview from '../PreviewForms/WebsitePreview'
import PdfPreview from '../PreviewForms/PdfPreview'
import Mp3Preview from '../PreviewForms/Mp3Preview'
import VideoPreview from '../PreviewForms/VideoPreview'
import ImagePreview from '../PreviewForms/ImagePreview'
import PreviewNavigationPhone from '../common/PreviewNavigationPhone/PreviewNavigationPhone'
import WifiPreview from '../PreviewForms/WifiPreview'
import TextPreview from '../PreviewForms/TextPreview'

const EditCodeLandingPreview = () => {
    const {
        type,
        dynamicWebsiteStyles,
        dynamicPdfStyles,
        dynamicMP3Styles,
        dynamicVideoStyles,
        dynamicImageStyles,
        dynamicWifiStyles,
        dynamicTextStyles,
        designPrimaryColor,
        designSecondaryColor,
        designTextColor,
        designButtonColor,
    } = useEditQrCodeContext()

    const pageContent = useMemo(() => {
        switch (type) {
            case 'website_url': {
                return <WebsitePreview data={dynamicWebsiteStyles} />
            }
            case 'pdf': {
                return <PdfPreview data={{ ...dynamicPdfStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'mp3': {
                return <Mp3Preview data={{ ...dynamicMP3Styles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'video': {
                return <VideoPreview data={{ ...dynamicVideoStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'images': {
                return <ImagePreview data={{ ...dynamicImageStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'wifi': {
                return <WifiPreview data={{ ...dynamicWifiStyles, type: dynamicWifiStyles.network_type, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'text': {
                return <TextPreview data={{ ...dynamicTextStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            default: {
                return ''
            }
        }
    }, [type, dynamicWebsiteStyles, dynamicPdfStyles, dynamicMP3Styles, dynamicVideoStyles, dynamicImageStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor, dynamicWifiStyles, dynamicTextStyles])

    return (
        <>
            <PreviewNavigationPhone color={'#000'} />
            {pageContent}
        </>
    )
}

export default EditCodeLandingPreview