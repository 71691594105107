import React, { useMemo } from 'react'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import WebsitePreview from '../PreviewForms/WebsitePreview'
import PreviewNavigationPhone from '../common/PreviewNavigationPhone/PreviewNavigationPhone'
import PdfPreview from '../PreviewForms/PdfPreview'
import Mp3Preview from '../PreviewForms/Mp3Preview'
import VideoPreview from '../PreviewForms/VideoPreview'
import ImagePreview from '../PreviewForms/ImagePreview'
import TextPreview from '../PreviewForms/TextPreview'
import WifiPreview from '../PreviewForms/WifiPreview'

const DashboardCreationPreview = () => {
    const {
        userQrType,
        dynamicWebsiteStyles,
        dynamicPdfStyles,
        dynamicMP3Styles,
        dynamicVideoStyles,
        dynamicImageStyles,
        dynamicTextStyles,
        dynamicWifiStyles,
        designPrimaryColor,
        designSecondaryColor,
        designTextColor,
        designButtonColor,
    } = useUserCodeCreationContext()

    const pageContent = useMemo(() => {
        switch (userQrType) {
            case 'URL': {
                return <WebsitePreview data={dynamicWebsiteStyles} />
            }
            case 'PDF': {
                return <PdfPreview data={{ ...dynamicPdfStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'MP3': {
                return <Mp3Preview data={{ ...dynamicMP3Styles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'Video': {
                return <VideoPreview data={{ ...dynamicVideoStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'Image': {
                return <ImagePreview data={{ ...dynamicImageStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'Text': {
                return <TextPreview creationPreview={true} data={{ ...dynamicTextStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            case 'Wi-Fi': {
                return <WifiPreview data={{ ...dynamicWifiStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor }} />
            }
            default: {
                return ''
            }
        }
    }, [userQrType, dynamicWebsiteStyles, dynamicPdfStyles, dynamicMP3Styles, dynamicVideoStyles, dynamicImageStyles, dynamicTextStyles, dynamicWifiStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor])

    return (
        <>
            <PreviewNavigationPhone color={'#000'} />
            {pageContent}
        </>
    )
}

export default DashboardCreationPreview