import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import rgbHex from 'rgb-hex';

import { useCreateDynamicImagesQrCodeMutation, useCreateDynamicMp3QrCodeMutation, useCreateDynamicPdfQrCodeMutation, useCreateDynamicTextCodeMutation, useCreateDynamicVideoQrCodeMutation, useCreateDynamicWebsiteQrCodeMutation, useCreateDynamicWifiCodeMutation, useCreatePublicIdQuery, useSaveQrCodeMutation, useUploadImagesDynamicImagesQrCodeMutation, useCustomLoggerMutation } from "../api/api";
import { base64ToBlob, convertQrCodeToImage, fileToBlob } from "../helpers/functions";
import { COLOR_PALETTE_LIST, DASHBOARD_QR_CODE_TYPES } from "../helpers/constants";
import { useCustomNavigate } from "../hooks/useCustomNavigate";
import useToasts from "../hooks/useToasts";

const UserCodeCreationContext = React.createContext();

export const useUserCodeCreationContext = () => React.useContext(UserCodeCreationContext);

export const UserCodeCreationProvider = ({ children }) => {
    const [step, setStep] = useState(1);
    const [isDynamic, setIsDynamic] = useState(true)
    const [type, setType] = useState('link')
    const [staticValue, setStaticValue] = useState('')
    const [foregroundColor, setForegroundColor] = useState('#000000')
    const [backgroundColor, setBackgroundColor] = useState('#ffffff')
    const [selectForegroundColor, setSelectForegroundColor] = useState(false)
    const [selectBackgroundColor, setSelectBackgroundColor] = useState(false)
    const [allowImage, setAllowImage] = useState(true)
    const [image, setImage] = useState('')
    const [useBackground, setUseBackground] = useState(true)
    const [contentValues, setContentValues] = useState({})
    const [publicId, setPublicId] = useState('')
    const [showAllTypes, setShowAllTypes] = useState(false)
    const [expandColor, setExpandColor] = useState(false)
    const [expandIcons, setExpandIcons] = useState(false)
    const [expandFrames, setExpandFrames] = useState(false)
    const [expandPreviewUpgrade, setExpandPreviewUpgrade] = useState(true)
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [showMobilePreview, setShowMobilePreview] = useState(false)
    const [showConvert, setShowConvert] = useState(false)
    const [frame, setFrame] = useState(1)
    const [scanText, setScanText] = useState('')
    const [scanColor, setScanColor] = useState('#000000')
    const [selectScanColor, setSelectScanColor] = useState(false)
    const [scanFont, setScanFont] = useState('Sans-Serif')
    const [activeTab, setActiveTab] = useState('My Codes')
    const [showGlobalSearch, setShowGlobalSearch] = useState(false)
    const [convertCodeData, setConvertCodeData] = useState({})
    const [codeName, setCodeName] = useState('')
    const [userQrType, setUserQrType] = useState('URL')
    const [searchFilter, setSearchFilter] = useState('')
    const [editDesign, setEditDesign] = useState('')
    const [expandEditQrCode, setExpandEditQrCode] = useState(true)
    const [expandEditLanding, setExpandEditLanding] = useState(true)
    const [designPrimaryColor, setDesignPrimaryColor] = useState('')
    const [designSecondaryColor, setDesignSecondaryColor] = useState('')
    const [designTextColor, setDesignTextColor] = useState('')
    const [designButtonColor, setDesignButtonColor] = useState('')
    const [selectDesignPrimaryColor, setSelectDesignPrimaryColor] = useState(false)
    const [selectDesignSecondaryColor, setSelectDesignSecondaryColor] = useState(false)
    const [selectDesignTextColor, setSelectDesignTextColor] = useState(false)
    const [selectDesignButtonColor, setSelectDesignButtonColor] = useState(false)
    const [mobileCreation, setMobileCreation] = useState(false)
    const [mobileCreationStep, setMobileCreationStep] = useState(1)
    const [isGenerating, setIsGenerating] = useState(false)
    const [canvasStyles, setCanvasStyles] = useState({})
    const [desktopCreation, setDesktopCreation] = useState(false)
    const [editQrCode, setEditQrCode] = useState(false)
    const [saveImageUrl, setSaveImageUrl] = useState(null)
    const [view, setView] = useState('Grid')
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)
    const [selectedColorPalette, setSelectedColorPalette] = useState(null)
    const [tempDesign, setTempDesign] = useState({})
    const [dynamicWebsiteStyles, setDynamicWebsiteStyles] = useState({
        website: 'https://'
    })
    const [dynamicPdfStyles, setDynamicPdfStyles] = useState({
        files: [],
        logo: null,
        image: null,
        company: '',
        title: '',
        description: '',
        website: '',
        button: '',
    })
    const [dynamicMP3Styles, setDynamicMP3Styles] = useState({
        file: '',
        logo: null,
        title: '',
        description: '',
        website: '',
        button: '',
        allowDownload: false,
        redirect: '',
    })
    const [dynamicVideoStyles, setDynamicVideoStyles] = useState({
        files: [],
        logo: null,
        link: '',
        showDirectly: false,
        highlightVideo: false,
        autoplayVideo: false,
        company: '',
        title: '',
        description: '',
        button: '',
        website: '',
        redirect: '',
    })
    const [dynamicImageStyles, setDynamicImageStyles] = useState({
        files: [],
        title: '',
        description: '',
        website: '',
        button: '',
        redirect: '',
    })
    const [dynamicTextStyles, setDynamicTextStyles] = useState({
        text: '',
        subject: '',
        company: '',
        logo: null,
    })
    const [dynamicWifiStyles, setDynamicWifiStyles] = useState({
        network_name: '',
        network_type: 'WEP',
        password: '',
        hidden: false,
        logo: null,
        cover: null,
    })

    const canvasRef = useRef(null)
    const scanColorRef = useRef(null)
    const canvasCreationRef = useRef(null)
    const designPrimaryColorRef = useRef(null)
    const designSecondaryColorRef = useRef(null)
    const designTextColorRef = useRef(null)
    const designButtonColorRef = useRef(null)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const { staticGeneratedToast, dynamicGeneratedToast, errorToast, limitToast } = useToasts()

    const user = useSelector((state) => state.app.user)
    const settings = useSelector((state) => state.app.settings)
    const subscription = useSelector((state) => state.app.subscription)

    const { data: publicIdData } = useCreatePublicIdQuery(user?.accessToken)

    const [saveQrCode] = useSaveQrCodeMutation()
    const [saveDynamicWebsiteQrCode] = useCreateDynamicWebsiteQrCodeMutation()
    const [saveDynamicPdfQrCode] = useCreateDynamicPdfQrCodeMutation()
    const [saveDynamicVideoQrCode] = useCreateDynamicVideoQrCodeMutation()
    const [saveDynamicMp3QrCode] = useCreateDynamicMp3QrCodeMutation()
    const [saveDynamicImageQrCode] = useCreateDynamicImagesQrCodeMutation()
    const [saveDynamicTextQrCode] = useCreateDynamicTextCodeMutation()
    const [saveDynamicWifiQrCode] = useCreateDynamicWifiCodeMutation()
    const [uploadImagesDynamicImagesQrCode] = useUploadImagesDynamicImagesQrCodeMutation()
    const [customLogger] = useCustomLoggerMutation()

    const imageSettings = useMemo(() => {
        return {
            src: image ? image : '',
            height: user ? 34 : 48,
            width: user ? 34 : 48,
            excavate: false,
        }
    }, [image, user]);

    const handleChangeForegroundColor = (value) => {
        setForegroundColor("#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a))
    }

    const handleChangeBackgroundColor = (value) => {
        setBackgroundColor("#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a))
    }

    const handleForegroundColor = () => {
        setSelectForegroundColor(true)
        setSelectBackgroundColor(false)
    }

    const handleBackgroundColor = () => {
        setSelectBackgroundColor(true)
        setSelectForegroundColor(false)
    }

    const handleResetToDefault = () => {
        setForegroundColor('#000000')
        setBackgroundColor('#ffffff')
        setUseBackground(true)
    }

    const handleResetToDefaultImage = () => {
        setImage('')
    }

    const handleShowFeedbackModal = () => {
        if (!settings?.is_feedback_given) {
            setShowFeedbackModal(true)
        }
    }

    const applyTempDesignChanges = () => {
        setDesignPrimaryColor(tempDesign.designPrimaryColor)
        setDesignSecondaryColor(tempDesign.designSecondaryColor)
        setDesignTextColor(tempDesign.designTextColor)
        setDesignButtonColor(tempDesign.designButtonColor)
        setSelectedColorPalette(tempDesign.selectedColorPalette)
        setForegroundColor(tempDesign.foregroundColor)
        setBackgroundColor(tempDesign.backgroundColor)
        setImage(tempDesign.image)
        setFrame(tempDesign.frame)
        setScanText(tempDesign.scanText)
        setScanColor(tempDesign.scanColor)
        setScanFont(tempDesign.scanFont)
    }

    const createTempVersionOfDesign = () => {
        setTempDesign({
            designPrimaryColor,
            designSecondaryColor,
            designTextColor,
            designButtonColor,
            selectedColorPalette,
            foregroundColor,
            backgroundColor,
            image,
            frame,
            scanText,
            scanColor,
            scanFont,
        })
    }

    const handleDownloadQrCode = (type) => {
        if (user) {
            const canvas = canvasRef.current.children[0]
            let url;
            if (type === 'PNG') {
                url = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            } else {
                url = canvas.toDataURL('image/png').replace('image/jpg', 'image/octet-stream');
            }

            let downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = type === 'PNG' ? 'qr-code.png' : 'qr-code.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } else {
            navigate('/sign-up')
        }
    }

    const handleSelectPalette = (palette) => {
        setSelectedColorPalette(palette.id)
        setDesignPrimaryColor(palette.primary_color)
        setDesignSecondaryColor(palette.secondary_color)
        setDesignTextColor(palette.text_color)
        setDesignButtonColor(palette.button_color)
    }

    const generateCodeDashboard = async (handleConvertData = () => { }, imageUrl = '') => {
        setIsGenerating(true)

        const url = imageUrl ? imageUrl : await convertQrCodeToImage(canvasCreationRef, imageSettings, 4)

        const blobImage = base64ToBlob(url, 'image/png')

        const formData = new FormData()

        formData.append('image', blobImage)

        if (!isDynamic) {
            const payloadData = JSON.stringify({
                name: codeName ? codeName : t("untitled"),
                styles: {
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                public_id: uuidv4(),
                data: {
                    content: staticValue,
                    kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                }
            })

            formData.append('payload', payloadData)

            saveQrCode(formData).then((res) => {
                if ('error' in res) {
                    if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                        limitToast()
                    } else {
                        errorToast('create')
                    }
                    setIsGenerating(false)
                } else {
                    resetCreationAfterSave()
                    if (!imageUrl) {
                        if (subscription && subscription?.plan !== 'Free') {
                            staticGeneratedToast(res.data, handleConvertData)
                        }
                        handleShowFeedbackModal()
                    }

                    customLogger({
                        action: 'create',
                        description: `Created Static QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                    })
                }
            })

            if (subscription && subscription?.plan === 'Free') {
                navigate('/upgrade-plan-new')
            }
        } else {
            if (userQrType === 'URL') {
                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            website_url: dynamicWebsiteStyles.website,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                formData.append('payload', payloadData)

                saveDynamicWebsiteQrCode(formData).then((res) => {
                    if ('error' in res) {
                        if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                            limitToast()
                        } else {
                            errorToast('create')
                        }
                        setIsGenerating(false)
                    } else {
                        resetCreationAfterSave()
                        if (!imageUrl) {
                            if (subscription && subscription?.plan !== 'Free') {
                                dynamicGeneratedToast(codeName)
                            }
                            handleShowFeedbackModal()
                        }

                        customLogger({
                            action: 'create',
                            description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                        })
                    }
                })

                if (subscription && subscription?.plan === 'Free') {
                    navigate('/upgrade-plan-new')
                }
            } else if (userQrType === 'PDF') {
                let coverBlob;
                let logoBlob;

                if (dynamicPdfStyles.image) {
                    coverBlob = await fileToBlob(dynamicPdfStyles.image)
                }

                if (dynamicPdfStyles.logo) {
                    logoBlob = await fileToBlob(dynamicPdfStyles.logo)
                }

                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            primary_color: designPrimaryColor,
                            secondary_color: designSecondaryColor,
                            text_color: designTextColor,
                            button_color: designButtonColor,
                            title: dynamicPdfStyles.title,
                            description: dynamicPdfStyles.description,
                            company_name: dynamicPdfStyles.company,
                            website: dynamicPdfStyles.website,
                            button_text: dynamicPdfStyles.button,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                for (const file of Array.from(dynamicPdfStyles.files)) {
                    const res = await fileToBlob(file)
                    formData.append('pdf_files', res)
                }

                formData.append('payload', payloadData)
                formData.append('cover', coverBlob)
                formData.append('logo', logoBlob)

                if (dynamicPdfStyles.logo && dynamicPdfStyles.image && dynamicPdfStyles.files.length > 0) {
                    saveDynamicPdfQrCode(formData).then((res) => {
                        if ('error' in res) {
                            if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                                limitToast()
                            } else {
                                errorToast('create')
                            }
                            setIsGenerating(false)
                        } else {
                            resetCreationAfterSave()
                            if (!imageUrl) {
                                if (subscription && subscription?.plan !== 'Free') {
                                    dynamicGeneratedToast(codeName)
                                }
                                handleShowFeedbackModal()
                            }

                            customLogger({
                                action: 'create',
                                description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                            })
                        }
                    })

                    if (subscription && subscription?.plan === 'Free') {
                        navigate('/upgrade-plan-new')
                    }
                }
            } else if (userQrType === 'MP3') {
                let logoBlob;
                let audioBlob;

                if (dynamicMP3Styles.file) {
                    audioBlob = await fileToBlob(dynamicMP3Styles.file)
                }

                if (dynamicMP3Styles.logo) {
                    logoBlob = await fileToBlob(dynamicMP3Styles.logo)
                }

                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            primary_color: designPrimaryColor,
                            secondary_color: designSecondaryColor,
                            text_color: designTextColor,
                            button_color: designButtonColor,
                            title: dynamicMP3Styles.title,
                            description: dynamicMP3Styles.description,
                            button_text: dynamicMP3Styles.button,
                            button_redirect_link: dynamicMP3Styles.redirect,
                            website: dynamicMP3Styles.website,
                            allowDownload: dynamicMP3Styles.allowDownload,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                formData.append('payload', payloadData)
                formData.append('logo', logoBlob)
                formData.append('mp3_file', audioBlob)

                if (dynamicMP3Styles.file && dynamicMP3Styles.logo) {
                    saveDynamicMp3QrCode(formData).then((res) => {
                        if ('error' in res) {
                            if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                                limitToast()
                            } else {
                                errorToast('create')
                            }
                            setIsGenerating(false)
                        } else {
                            resetCreationAfterSave()
                            if (!imageUrl) {
                                if (subscription && subscription?.plan !== 'Free') {
                                    dynamicGeneratedToast(codeName)
                                }
                                handleShowFeedbackModal()
                            }

                            customLogger({
                                action: 'create',
                                description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                            })
                        }
                    })

                    if (subscription && subscription?.plan === 'Free') {
                        navigate('/upgrade-plan-new')
                    }
                }
            } else if (userQrType === 'Video') {
                let logoBlob;

                if (dynamicVideoStyles.logo) {
                    logoBlob = await fileToBlob(dynamicVideoStyles.logo)
                }

                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            primary_color: designPrimaryColor,
                            secondary_color: designSecondaryColor,
                            text_color: designTextColor,
                            button_color: designButtonColor,
                            title: dynamicVideoStyles.title,
                            description: dynamicVideoStyles.description,
                            button_text: dynamicVideoStyles.button,
                            button_redirect_link: dynamicVideoStyles.redirect,
                            website: dynamicVideoStyles.website,
                            video_url: dynamicVideoStyles.link,
                            company_name: dynamicVideoStyles.company,
                            show_directly: dynamicVideoStyles.showDirectly,
                            highlight_first: dynamicVideoStyles.highlightVideo,
                            autoplay_first: dynamicVideoStyles.autoplayVideo,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                for (const file of Array.from(dynamicVideoStyles.files)) {
                    const res = await fileToBlob(file)
                    formData.append('video_files', res)
                }

                formData.append('payload', payloadData)
                formData.append('logo', logoBlob)

                if (dynamicVideoStyles.logo && (dynamicVideoStyles.files.length > 0 || dynamicVideoStyles.link)) {
                    saveDynamicVideoQrCode(formData).then((res) => {
                        if ('error' in res) {
                            if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                                limitToast()
                            } else {
                                errorToast('create')
                            }
                            setIsGenerating(false)
                        } else {
                            resetCreationAfterSave()
                            if (!imageUrl) {
                                if (subscription && subscription?.plan !== 'Free') {
                                    dynamicGeneratedToast(codeName)
                                }
                                handleShowFeedbackModal()
                            }

                            customLogger({
                                action: 'create',
                                description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                            })
                        }
                    })

                    if (subscription && subscription?.plan === 'Free') {
                        navigate('/upgrade-plan-new')
                    }
                }
            } else if (userQrType === 'Image') {
                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            primary_color: designPrimaryColor,
                            secondary_color: designSecondaryColor,
                            text_color: designTextColor,
                            button_color: designButtonColor,
                            title: dynamicImageStyles.title,
                            description: dynamicImageStyles.description,
                            button_text: dynamicImageStyles.button,
                            button_redirect_link: dynamicImageStyles.redirect,
                            website_url: dynamicImageStyles.website,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                formData.append('payload', payloadData)

                if (dynamicImageStyles.files.length > 0) {
                    saveDynamicImageQrCode(formData).then(async (res) => {
                        if ('error' in res) {
                            if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                                limitToast()
                            } else {
                                errorToast('create')
                            }
                            setIsGenerating(false)
                        } else {
                            resetCreationAfterSave()
                            if (!imageUrl) {
                                if (subscription && subscription?.plan !== 'Free') {
                                    dynamicGeneratedToast(codeName)
                                }
                                handleShowFeedbackModal()
                            }

                            customLogger({
                                action: 'create',
                                description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                            })

                            for (let image of Array.from(dynamicImageStyles.files)) {
                                const imagesFormData = new FormData()
                                const convertedImage = await fileToBlob(image)
                                imagesFormData.append('image', convertedImage)

                                await uploadImagesDynamicImagesQrCode({ data: imagesFormData, id: res.data.id })
                            }
                        }
                    })

                    if (subscription && subscription?.plan === 'Free') {
                        navigate('/upgrade-plan-new')
                    }
                }
            } else if (userQrType === 'Text') {
                let logoBlob;

                if (dynamicTextStyles.logo) {
                    logoBlob = await fileToBlob(dynamicTextStyles.logo)
                }

                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            primary_color: designPrimaryColor,
                            secondary_color: designSecondaryColor,
                            text_color: designTextColor,
                            button_color: designButtonColor,
                            text: dynamicTextStyles.text,
                            company_name: dynamicTextStyles.company,
                            subject: dynamicTextStyles.subject,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                formData.append('payload', payloadData)
                formData.append('logo', logoBlob)

                saveDynamicTextQrCode(formData).then((res) => {
                    if ('error' in res) {
                        if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                            limitToast()
                        } else {
                            errorToast('create')
                        }
                        setIsGenerating(false)
                    } else {
                        resetCreationAfterSave()
                        if (!imageUrl) {
                            if (subscription && subscription?.plan !== 'Free') {
                                dynamicGeneratedToast(codeName)
                            }
                            handleShowFeedbackModal()
                        }

                        customLogger({
                            action: 'create',
                            description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                        })
                    }
                })

                if (subscription && subscription?.plan === 'Free') {
                    navigate('/upgrade-plan-new')
                }
            } else if (userQrType === 'Wi-Fi') {
                let logoBlob;
                let coverBlob;

                if (dynamicWifiStyles.cover) {
                    coverBlob = await fileToBlob(dynamicWifiStyles.cover)
                }

                if (dynamicWifiStyles.logo) {
                    logoBlob = await fileToBlob(dynamicWifiStyles.logo)
                }

                const payloadData = JSON.stringify({
                    name: codeName ? codeName : t("untitled"),
                    styles: {
                        background: backgroundColor,
                        foreground: foregroundColor,
                        icon: image,
                        frame: frame,
                        scanColor: scanColor,
                        scanText: scanText,
                        scanFont: scanFont,
                    },
                    public_id: publicId,
                    data: {
                        content: {
                            primary_color: designPrimaryColor,
                            secondary_color: designSecondaryColor,
                            text_color: designTextColor,
                            button_color: designButtonColor,
                            network_name: dynamicWifiStyles.network_name,
                            type: dynamicWifiStyles.network_type,
                            password: dynamicWifiStyles.password,
                            hidden: false,
                        },
                        kind: DASHBOARD_QR_CODE_TYPES[userQrType],
                    }
                })

                formData.append('payload', payloadData)
                formData.append('logo', logoBlob)
                formData.append('cover', coverBlob)

                saveDynamicWifiQrCode(formData).then((res) => {
                    if ('error' in res) {
                        if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                            limitToast()
                        } else {
                            errorToast('create')
                        }
                        setIsGenerating(false)
                    } else {
                        resetCreationAfterSave()
                        if (!imageUrl) {
                            if (subscription && subscription?.plan !== 'Free') {
                                dynamicGeneratedToast(codeName)
                            }
                            handleShowFeedbackModal()
                        }

                        customLogger({
                            action: 'create',
                            description: `Created Dynamic QR Code ${DASHBOARD_QR_CODE_TYPES[userQrType]}`
                        })
                    }
                })

                if (subscription && subscription?.plan === 'Free') {
                    navigate('/upgrade-plan-new')
                }
            }
        }
    }

    const resetCreationAfterSave = () => {
        setCodeName('')
        setMobileCreation(false)
        resetStyles()
        setMobileCreationStep(1)
        setIsGenerating(false)
        setEditDesign(false)
        setDesktopCreation(false)
        setIsDynamic(true)
        setUserQrType('URL')
    }

    const resetDesignStyles = () => {
        setForegroundColor('#000000')
        setBackgroundColor('#ffffff')
        setImage('')
        setFrame(1)
        setScanText('')
        setScanFont('Sans-Serif')
        setScanColor('#000000')
        setDesignPrimaryColor('')
        setDesignSecondaryColor('')
        setDesignTextColor('')
        setDesignButtonColor('')
    }

    const resetMobileQrCodeStyles = () => {
        setForegroundColor('#000000')
        setBackgroundColor('#ffffff')
        setImage('')
        setFrame(1)
        setScanText('')
        setScanFont('Sans-Serif')
        setScanColor('#000000')
    }

    const resetMobileLandingStyles = () => {
        setDesignPrimaryColor('')
        setDesignSecondaryColor('')
        setDesignTextColor('')
        setDesignButtonColor('')
    }

    const resetStyles = () => {
        resetDesignStyles()
        setDynamicWebsiteStyles({
            website: 'https://'
        })
        setDynamicPdfStyles({
            files: [],
            logo: null,
            image: null,
            company: '',
            title: '',
            description: '',
            website: '',
            button: '',
        })
        setDynamicMP3Styles({
            file: '',
            logo: null,
            title: '',
            description: '',
            website: '',
            button: '',
            allowDownload: false,
            redirect: '',
        })
        setDynamicVideoStyles({
            files: [],
            logo: null,
            link: '',
            showDirectly: false,
            highlightVideo: false,
            autoplayVideo: false,
            company: '',
            title: '',
            description: '',
            button: '',
            website: '',
            redirect: '',
        })
        setDynamicImageStyles({
            files: [],
            title: '',
            description: '',
            website: '',
            button: '',
            redirect: '',
        })
        setDynamicTextStyles({
            text: '',
            subject: '',
            company: '',
            logo: null,
        })
        setDynamicWifiStyles({
            network_name: '',
            network_type: 'WEP',
            password: '',
            hidden: false,
            logo: null,
            cover: null,
        })
    }

    useEffect(() => {
        const palette = COLOR_PALETTE_LIST.find((el) =>
            el?.primary_color?.toLowerCase() === designPrimaryColor?.toLowerCase() &&
            el?.secondary_color?.toLowerCase() === designSecondaryColor?.toLowerCase() &&
            el?.text_color?.toLowerCase() === designTextColor?.toLowerCase() &&
            el?.button_color?.toLowerCase() === designButtonColor?.toLowerCase())

        if (!palette) {
            setSelectedColorPalette(null)
        } else {
            setSelectedColorPalette(palette.id)
        }
    }, [selectedColorPalette, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor])

    useEffect(() => {
        const view = localStorage.getItem('view')

        if (view) {
            setView(view)
        }
    }, [])

    useEffect(() => {
        if (!useBackground) {
            setBackgroundColor("#ffffff")
        }
    }, [useBackground])

    useEffect(() => {
        setPublicId(publicIdData?.public_id)
        if (isDynamic && publicIdData) {
            setStaticValue(`${window.location.origin}/code/${publicIdData.public_id}`)
        } else {
            setStaticValue('')
        }
    }, [isDynamic, publicIdData])

    useEffect(() => {
        setStaticValue('')
    }, [type])

    useEffect(() => {
        resetStyles()

        if (!isDynamic) {
            setStaticValue('')
        } else {
            if (userQrType === 'PDF') {
                setDesignPrimaryColor('#E2E8F0')
                setDesignSecondaryColor('#FFFFFF')
                setDesignTextColor('#334155')
                setDesignButtonColor('#7D8898')
            } else if (userQrType === 'MP3') {
                setDesignPrimaryColor('#E2E8F0')
                setDesignSecondaryColor('#FFFFFF')
                setDesignTextColor('#334155')
                setDesignButtonColor('#7D8898')
            } else if (userQrType === 'Video') {
                setDesignPrimaryColor('#E2E8F0')
                setDesignSecondaryColor('#FFFFFF')
                setDesignTextColor('#334155')
                setDesignButtonColor('#7D8898')
            } else if (userQrType === 'Image') {
                setDesignPrimaryColor('#FFFFFF')
                setDesignSecondaryColor('#E2E8F0')
                setDesignTextColor('#334155')
                setDesignButtonColor('#7D8898')
            } else if (userQrType === 'Text') {
                setDesignPrimaryColor('#E2E8F0')
                setDesignSecondaryColor('#FFFFFF')
                setDesignTextColor('#334155')
                setDesignButtonColor('#FFFFFF')
            } else if (userQrType === 'Wi-Fi') {
                setDesignPrimaryColor('#FFFFFF')
                setDesignSecondaryColor('#FFFFFF')
                setDesignTextColor('#334155')
                setDesignButtonColor('#FFFFFF')
            }
        }
    }, [userQrType, isDynamic])

    useEffect(() => {
        setUserQrType('URL')
        if (!isDynamic) {
            setStaticValue('')
        } else {
            setStaticValue(`${window.location.origin}/code/${publicId}`)
        }
    }, [isDynamic, publicId])

    useEffect(() => {
        if (settings?.dynamic_qr_codes === false) {
            setIsDynamic(false)
        } else {
            setIsDynamic(true)
        }
    }, [settings])

    useEffect(() => {
        if (saveImageUrl && subscription) {
            (async () => {
                setSaveImageUrl(null)
                const res = await saveQrCode(saveImageUrl)
                setCodeName('')
                setConvertCodeData(res.data)
                setShowConvert(true)
                resetStyles()
                setMobileCreationStep(1)
            })()
        }
    }, [saveImageUrl, subscription])

    useEffect(() => {
        const borderWidth = user ? 4 : 8

        if (frame === 1) {
            setCanvasStyles({})
        } else if (frame === 2) {
            setCanvasStyles({
                border: `${borderWidth}px solid black`,
            })
        } else if (frame === 3) {
            setCanvasStyles({
                border: `${borderWidth}px solid black`,
                borderRadius: '25px'
            })
        } else if (frame === 4) {
            if (user) {
                setCanvasStyles({
                    border: `${borderWidth}px solid black`,
                    width: '225px',
                    height: '225px',
                    borderRadius: '100%',
                    padding: scanText ? '25px' : '35px',
                    fontSize: '20px',
                })
            } else {
                setCanvasStyles({
                    border: `${borderWidth}px solid black`,
                    width: '306px',
                    height: '306px',
                    borderRadius: '100%',
                    padding: scanText ? '40px' : '50px',
                })
            }
        }
    }, [frame, scanText, user])

    const value = {
        step, setStep,
        isDynamic, setIsDynamic,
        type, setType, setForegroundColor,
        staticValue, setStaticValue,
        handleResetToDefault, foregroundColor,
        handleForegroundColor, selectForegroundColor,
        setSelectForegroundColor, setSelectBackgroundColor,
        handleChangeForegroundColor, useBackground,
        setUseBackground, backgroundColor, setBackgroundColor,
        handleBackgroundColor, selectBackgroundColor,
        handleChangeBackgroundColor, allowImage,
        setAllowImage, setImage, image, imageSettings,
        contentValues, setContentValues, canvasRef,
        dynamicWebsiteStyles, setDynamicWebsiteStyles,
        dynamicVideoStyles, setDynamicVideoStyles,
        publicId, setPublicId, showAllTypes, setShowAllTypes,
        expandColor, setExpandColor, expandIcons, setExpandIcons,
        expandFrames, setExpandFrames, handleDownloadQrCode,
        expandPreviewUpgrade, setExpandPreviewUpgrade,
        handleResetToDefaultImage, showDownloadModal, setShowDownloadModal,
        showMobilePreview, setShowMobilePreview,
        showConvert, setShowConvert, frame, setFrame,
        scanText, setScanText, scanColor, setScanColor,
        scanColorRef, selectScanColor, setSelectScanColor,
        scanFont, setScanFont, activeTab, setActiveTab,
        showGlobalSearch, setShowGlobalSearch,
        convertCodeData, setConvertCodeData,
        codeName, setCodeName, userQrType, setUserQrType,
        generateCodeDashboard, canvasCreationRef,
        searchFilter, setSearchFilter, dynamicPdfStyles, setDynamicPdfStyles,
        dynamicMP3Styles, setDynamicMP3Styles, dynamicImageStyles,
        setDynamicImageStyles, editDesign, setEditDesign,
        expandEditQrCode, setExpandEditQrCode, expandEditLanding,
        setExpandEditLanding, designPrimaryColor, setDesignPrimaryColor,
        designSecondaryColor, setDesignSecondaryColor, designTextColor,
        setDesignTextColor, designButtonColor, setDesignButtonColor,
        designPrimaryColorRef, designSecondaryColorRef, designTextColorRef,
        designButtonColorRef, selectDesignPrimaryColor, setSelectDesignPrimaryColor,
        selectDesignSecondaryColor, setSelectDesignSecondaryColor,
        selectDesignTextColor, setSelectDesignTextColor,
        selectDesignButtonColor, setSelectDesignButtonColor, resetDesignStyles,
        mobileCreation, setMobileCreation, mobileCreationStep, setMobileCreationStep,
        resetMobileQrCodeStyles, resetMobileLandingStyles, resetStyles,
        isGenerating, setIsGenerating, canvasStyles, desktopCreation, setDesktopCreation,
        editQrCode, setEditQrCode, dynamicTextStyles, setDynamicTextStyles,
        dynamicWifiStyles, setDynamicWifiStyles, view, setView, showFeedbackModal,
        setShowFeedbackModal, selectedColorPalette, setSelectedColorPalette, handleSelectPalette,
        createTempVersionOfDesign, applyTempDesignChanges
    };

    return (
        <UserCodeCreationContext.Provider value={value}>
            {children}
        </UserCodeCreationContext.Provider>
    );
};
