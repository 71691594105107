import React from 'react'
import { useTranslation } from 'react-i18next'

const VideoPreview = ({ data }) => {
    const { t } = useTranslation()

    const videoCode = data.link.split('?v=')[1] ? data.link.split('?v=')[1] : data.link.split('be/')[1]

    const onClickRedirect = (link) => {
        const editedLink = link.includes('https://') || link.includes('http://') ? link : `https://${link}`
        const linkWithoutSpaces = editedLink.replaceAll(' ', '')

        window.open(linkWithoutSpaces, '_blank')
    }

    return (
        <div className='video-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
            <div className='video-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                <div className='video-company-logo-block'>
                    {data.logo ? (
                        <img className='video-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                    ) : (
                        <div className='video-logo-empty'></div>
                    )}
                </div>
                <span className='video-company-name' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                    {data.company ? data.company : t("companyName")}
                </span>
                <h5 className='video-title-text' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                    {data.title ? data.title : t("videoTitle")}
                </h5>
                <span className='video-description' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                    {data.description ? data.description : t("description")}
                </span>
                <button onClick={() => onClickRedirect(data.redirect)} className='video-button' style={{ backgroundColor: data.designButtonColor }}>
                    {data.button ? data.button : 'CTA'}
                </button>
            </div>
            <div className='video-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                <div className={(data.link || data.files.length > 0) ? 'video-preview-block-no-background' : 'video-preview-block'}>
                    {data.link ? (
                        <div className='video-preview-item-wrapper'>
                            <iframe
                                width={"100%"}
                                allowFullScreen='allowfullscreen'
                                title="123"
                                src={data.autoplayVideo ? `https://www.youtube.com/embed/${videoCode}?autoplay=1` : `https://www.youtube.com/embed/${videoCode}`}
                            ></iframe>
                        </div>
                    ) : data.files && data.files.length > 0 && Array.from(data.files).map((video, idx) => {
                        return (
                            <div key={idx} className='video-preview-item-wrapper'>
                                <video src={video.file ? video.file : URL.createObjectURL(video)} controlsList='nodownload' controls></video>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default VideoPreview