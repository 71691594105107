import React from 'react'
import { SlClose } from 'react-icons/sl'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BsFillCheckCircleFill } from 'react-icons/bs'

import { useChangeUserPlanMutation, useCustomLoggerMutation } from '../../api/api'
import { PRICE_SIGN_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import i18n from '../../i18n/config'

const PlanCardNew = ({ plan, isNew = false, newUser = false }) => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const [changePlan] = useChangeUserPlanMutation()
    const [customLogger] = useCustomLoggerMutation()

    const handleChoosePlan = async (price_id) => {
        if (subscription?.plan === 'Free') {
            if (user) {
                navigate(`/upgrade-plan/checkout/${price_id}/${plan.shortName}?isNewUser=${newUser}`)
                if (newUser) {
                    window.gtag('event', 'click_choose_plan_button', {
                        type: 'new_user',
                    })
                } else {
                    window.gtag('event', 'click_choose_plan_button', {
                        type: 'existing_user',
                    })
                }

                customLogger({
                    action: 'click',
                    description: `Upgrade Plan Select Plan ${plan.name}`
                })
            } else {
                navigate('/sign-up')
            }
        } else {
            const splittedPrice = plan.price_id.split(',')

            changePlan({
                price_id_stripe: splittedPrice[0],
                price_id_paypal: plan.paypal_plan_id,
                plan_name: plan.name,
            })
        }
    }

    return (
        <div className={plan.best ? "upgrade-plan-new-card-block-best" : "upgrade-plan-new-card-block"}>
            <div className="flex flex-col w-full">
                <span className="upgrade-plan-name">
                    {plan.name}
                </span>
                <div className='upgrade-plan-price-block-wrapper'>
                    <div className={`upgrade-plan-price-block ${isNew ? 'mb-[16px] mt-2' : ''}`}>
                        <span className="upgrade-plan-cost">
                            {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}
                            {plan.price[i18n.language] ?? plan.price['en']}
                        </span>
                        <span className="upgrade-plan-period">
                            {plan.price_per_period}
                        </span>
                    </div>
                </div>
                <div className={plan.best ? 'upgrade-plan-new-underline-best' : 'upgrade-plan-new-underline'}></div>
                <ul className="upgrade-plan-options-list">
                    {plan.options.map((option, index) => {
                        return (
                            <li key={index} className="upgrade-plan-active-option">
                                {plan.best ? (
                                    <BsFillCheckCircleFill className='min-width-upgrade-option-icon' size={20} fill='#FFFFFF' />
                                ) : (
                                    <>
                                        {
                                            option.available ? (
                                                <BsFillCheckCircleFill className='min-width-upgrade-option-icon' size={20} fill='#9747FF' />
                                            ) : (
                                                <SlClose className='min-width-upgrade-option-icon' size={20} fill='#7D8898' />
                                            )
                                        }
                                    </>
                                )}
                                <span className="upgrade-plan-active-option-text">
                                    {option.title}
                                </span>
                            </li>
                        )
                    })}
                </ul>
                <div className='upgrade-plan-new-note'>
                    {plan.note}
                </div>
            </div>
            <button
                disabled={user && subscription?.plan === plan.name ? true : false}
                onClick={() => handleChoosePlan(plan.price_id)}
                className={`choose-plan-button-new ${plan.best ? 'border-0' : ''}`}
            >
                {user && subscription?.plan === plan.name ? t("currentPlan") : user ? t("selectPlan") : t("upgrade")}
            </button>
            {
                plan.best && (
                    <div className='upgrade-plan-new-most-popular'>{t("mostPopular")}</div>
                )
            }
            {
                plan.shortName === 'Yearly' && (
                    <div className='upgrade-plan-new-save'>{t("save50Percents")}</div>
                )
            }
        </div>
    )
}

export default PlanCardNew