import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext';

import CreationContentDescription from '../../common/CreationContentDescription/CreationContentDescription';
import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle'
import EditListFilesBlock from '../../EditListFilesBlock/EditListFilesBlock'
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import UploadBlock from '../../common/UploadBlock/UploadBlock'
import EditFileBlock from '../../EditFileBlock/EditFileBlock';
import CodeNameInput from '../CodeNameInput/CodeNameInput';

const PdfForm = ({ triggerValidation, setIsValid }) => {
    const [filesErrors, setFilesErrors] = useState([])

    const { t } = useTranslation()

    const {
        dynamicPdfStyles, setDynamicPdfStyles
    } = useUserCodeCreationContext()

    const { register, trigger, formState: { errors, isValid } } = useForm({
        mode: 'onBlur'
    });

    const handleChangeLogoFile = (file) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'Logo')
        })

        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            logo: file,
        })
    }

    const handleChangeImageFile = (file) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'Cover')
        })

        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            image: file,
        })
    }

    const handleChangeFiles = (files) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'PDFs')
        })

        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            files: files,
        })
    }

    const handleChangeInput = (name, value) => {
        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            [name]: value,
        })
    }

    useEffect(() => {
        if (triggerValidation) {

            const triggerFunction = async () => {
                let result = true;

                result = await trigger()

                if (!dynamicPdfStyles.files || dynamicPdfStyles.files.length === 0) {
                    setFilesErrors((prev) => {
                        return [...prev, 'PDFs']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'PDFs')
                    })
                }

                if (!dynamicPdfStyles.logo) {
                    setFilesErrors((prev) => {
                        return [...prev, 'Logo']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'Logo')
                    })
                }

                if (!dynamicPdfStyles.image) {
                    setFilesErrors((prev) => {
                        return [...prev, 'Cover']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'Cover')
                    })
                }

                return result
            }

            triggerValidation(triggerFunction)
        }
    }, [triggerValidation, trigger, dynamicPdfStyles])

    useEffect(() => {
        if (setIsValid) {
            setIsValid(isValid)
        }
    }, [isValid, setIsValid])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            <CreationContentTitle title={"PDF(s)"} withStep={false} />
            <CreationContentDescription customStyles={{ marginTop: '-12px', marginBottom: '-8px' }} description={t("uploadOnePdf")} />
            <CodeNameInput />
            {dynamicPdfStyles.files.length > 0 ? (
                <EditListFilesBlock
                    files={Array.from(dynamicPdfStyles.files)}
                    onChangeFiles={handleChangeFiles}
                    type={"pdf"}
                    acceptList={"application/pdf"}
                    allowDeleteLast={true}
                />
            ) : (
                <UploadBlock
                    title={t("uploadPdfs")}
                    limit={'20MB'}
                    acceptList={'application/pdf'}
                    multiple={true}
                    onChangeFiles={handleChangeFiles}
                    uploadedFiles={dynamicPdfStyles.files.length}
                    isError={filesErrors.includes('PDFs')}
                />
            )}
            <span className='dynamic-form-underline'></span>
            <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("company")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.company}
                        placeholder={t("exTechAndCorp")}
                        className={errors.company ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("company", {
                            onChange: (e) => handleChangeInput('company', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.company && <p className='error-text'>{t("enterCompanyName")}</p>}
                </div>
                {dynamicPdfStyles.logo ? (
                    <EditFileBlock
                        file={dynamicPdfStyles.logo}
                        title={dynamicPdfStyles?.logo?.name ? dynamicPdfStyles?.logo?.name : t("logoFile")}
                        onFileChanged={handleChangeLogoFile}
                    />
                ) : (
                    <UploadLogoBlock
                        title={t("addLogo")}
                        limit={"5MB"}
                        acceptList={"image/*"}
                        multiple={false}
                        onChangeFiles={handleChangeLogoFile}
                        uploadedFiles={dynamicPdfStyles.logo ? 1 : 0}
                        isError={filesErrors.includes('Logo')}
                        errorText={t("logoFileMustBeUploaded")}
                    />
                )}
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("pdfTitle")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.title}
                        placeholder={t("exManifesto")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterPdfTitle")}</p>}
                </div>
                {dynamicPdfStyles.image ? (
                    <EditFileBlock
                        file={dynamicPdfStyles.image}
                        title={dynamicPdfStyles?.image?.name ? dynamicPdfStyles?.image?.name : t("coverFile")}
                        onFileChanged={handleChangeImageFile}
                    />
                ) : (
                    <UploadLogoBlock
                        title={t("addCover")}
                        limit={"5MB"}
                        acceptList={"image/*"}
                        multiple={false}
                        onChangeFiles={handleChangeImageFile}
                        uploadedFiles={dynamicPdfStyles.image ? 1 : 0}
                        isError={filesErrors.includes('Cover')}
                        errorText={t("coverFileMustBeUploaded")}
                    />
                )}
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.description}
                        placeholder={t("exFullTechAndCorpManifesto")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.button}
                        placeholder={t("exViewPdf")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
            </div>
        </form>
    )
}

export default PdfForm