import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { PLAN_NAME_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { userSignOut } from '../../firebase/auth/googleAuth'
import { PLAN_TO_FULL_NAME } from '../../helpers/constants'
import { useAuthContext } from '../../context/AuthContext'
import usePlanName from '../../hooks/usePlanName'
import { signOut } from '../../redux/appSlice'
import { api } from '../../api/api'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import SettingsTermsConditions from '../../assets/icons/settings/terms-conditions.svg'
import SettingsNotification from '../../assets/icons/settings/notification.svg'
import SettingsArrowBottom from '../../assets/icons/settings/arrow-bottom.svg'
import SettingsSettings from '../../assets/icons/settings/settings.svg'
import defaultAvatarJpg from '../../assets/images/default-avatar.webp'
import SettingsBilling from '../../assets/icons/settings/billing.svg'
import SettingsContact from '../../assets/icons/settings/contact.svg'
import SettingsPrivacy from '../../assets/icons/settings/privacy.svg'
import SettingsProfile from '../../assets/icons/settings/profile.svg'
import SettingsLogout from '../../assets/icons/settings/logout.svg'

const SettingsNavigation = ({ activeTab, setActiveTab, isMobile }) => {
    const subscription = useSelector(state => state.app.subscription)
    const user = useSelector(state => state.app.user)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()
    const dispatch = useDispatch()

    const { expandNavigation } = useAuthContext()
    const { planName } = usePlanName(subscription)

    const handleNavigateBackToPrevious = () => {
        setActiveTab('')
        navigate(-1)
    }

    const handleLogOut = async () => {
        await userSignOut()
        dispatch(signOut())
        dispatch(api.util.resetApiState());
        localStorage.removeItem('showTrialAlert')
        navigate('/')
    }

    return (
        <div className='settings-navigation-wrapper'>
            {isMobile && (
                <div className='settings-mobile-menu-tab-header'>
                    <h5 onClick={handleNavigateBackToPrevious} className='settings-tab-title'>
                        {isMobile && (
                            <img width={20} src={overviewPanelMobileBack} alt="" />
                        )}
                        {t("account")}
                        {isMobile && (
                            <div style={{ width: '20px' }} />
                        )}
                    </h5>
                </div>
            )}
            <div
                onClick={() => setActiveTab('Profile')}
                className={`settings-navigation-item first ${activeTab === 'Profile' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsProfile} alt="" />
                    {t("profile")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={() => setActiveTab('Notifications')}
                className={`settings-navigation-item ${activeTab === 'Notifications' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsNotification} alt="" />
                    {t("notifications")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={() => setActiveTab('QR Code Settings')}
                className={`settings-navigation-item ${activeTab === 'QR Code Settings' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsSettings} alt="" />
                    {t("qrCodeSettings")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={() => setActiveTab('Payments and Plan')}
                className={`settings-navigation-item ${activeTab === 'Payments and Plan' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsBilling} alt="" />
                    {t("paymentsAndPlan")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={() => setActiveTab('Contact Us')}
                className={`settings-navigation-item ${activeTab === 'Contact Us' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsContact} alt="" />
                    {t("contactUs")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={() => setActiveTab('Terms and Conditions')}
                className={`settings-navigation-item ${activeTab === 'Terms and Conditions' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsTermsConditions} alt="" />
                    {t("termsAndConditionsPageTitle")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={() => setActiveTab('Privacy Policy')}
                className={`settings-navigation-item last ${activeTab === 'Privacy Policy' ? 'active' : ''}`}
            >
                <div className='flex items-center gap-2'>
                    <img src={SettingsPrivacy} alt="" />
                    {t("privacyPolicy")}
                </div>
                <img className='setting-navigation-item-mobile-arrow' src={SettingsArrowBottom} alt="" />
            </div>
            <div
                onClick={handleLogOut}
                className={`settings-navigation-item sign-out`}
            >
                <img src={SettingsLogout} alt="" />
                {t("logOut")}
            </div>
            <div className={`corner-border-settings-navigation ${expandNavigation ? 'expanded' : ''}`}>
                <div className='corner-border-settings-purple-background'></div>
            </div>
            <div className={`user-icon-settings-navigation ${expandNavigation ? 'expanded' : ''}`}>
                <img
                    style={{ width: '36px', height: '36px', borderRadius: '8px' }}
                    className='footer-user-personal-image'
                    src={subscription?.profile_image ? subscription?.profile_image : defaultAvatarJpg}
                    alt=""
                />
                {expandNavigation && (
                    <div className='dashboard-navigation-bottom-user-base-info'>
                        <span className='dashboard-navigation-bottom-user-info-name'>
                            {user?.displayName && user?.displayName?.length > 0 ? (
                                <>
                                    {user?.displayName?.split(' ').length > 1 ? `${user?.displayName?.split(' ')[0]} ${user?.displayName?.split(' ')[1][0]}.` : user?.displayName}
                                </>
                            ) : (
                                <>{user?.email?.split('@')[0]}</>
                            )}
                        </span>
                        <span className='dashboard-navigation-bottom-user-info-plan'>
                            {PLAN_TO_FULL_NAME[planName] ? t(`${PLAN_NAME_TRANSLATIONS[PLAN_TO_FULL_NAME[planName]]}`) : t("freeTrial")}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SettingsNavigation