import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { IoMdMore } from 'react-icons/io'

import editCodeDeleteSvg from '../../assets/icons/edit-code-delete.svg'
import editListLinesSvg from '../../assets/icons/edit-list-lines.svg'
import editVideoIconSvg from '../../assets/icons/edit-video-icon.svg'
import pdfIconSvg from '../../assets/icons/pdf-icons.svg'

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const EditListFilesBlock = ({ files, title, onChangeFiles, type, acceptList, allowDeleteLast = false }) => {
    const [filesList, setFilesList] = useState([])
    const [changeId, setChangeId] = useState(null)

    const uploadInputRef = useRef(null)

    const { t } = useTranslation()

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            filesList,
            result.source.index,
            result.destination.index
        );

        const newFilesList = items.map((el) => el.content)

        setFilesList(items)
        onChangeFiles(newFilesList)
    }

    const handleChangeClick = (id) => {
        setChangeId(id)

        uploadInputRef.current.click()
    }

    const handleChangeFile = (e) => {
        if (e.target.files.length > 0) {
            const newFiles = e.target.files

            let updatedList = filesList.filter((el) => el.id !== changeId).map((el) => el.content)

            for (let file of Array.from(newFiles)) {
                updatedList.push(file)
            }

            onChangeFiles(updatedList)
        }
    }

    const handleDeleteCode = (id) => {
        const updatedList = filesList.filter((el) => el.id !== id)
        const newFilesList = updatedList.map((el) => el.content)

        setFilesList(updatedList)
        onChangeFiles(newFilesList)
    }

    useEffect(() => {
        if (files && files.length > 0) {
            (async () => {
                const updatedList = files.map((el, idx) => ({ id: `${idx}`, size: el.size ? (el.size / 1024).toFixed(2) : '', content: el }))

                setFilesList(updatedList)

                const updatedListWithSizes = []

                for (let file of updatedList) {
                    if (!file.size) {
                        let response;
                        if (type === 'image') {
                            response = await fetch(file.content.image)
                        } else {
                            response = await fetch(file.content.file)
                        }

                        const blob = await response.blob()

                        updatedListWithSizes.push({ ...file, size: (blob.size / 1024).toFixed(2) })
                    } else {
                        updatedListWithSizes.push(file)
                    }
                }

                setFilesList(updatedListWithSizes)
            })()
        }
    }, [files])

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            className='edit-files-list-block-wrapper'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {filesList && filesList.length > 0 && filesList.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided) => (
                                        <div
                                            className='edit-file-list-item-wrapper'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className='edit-file-left-side-block'>
                                                <img src={editListLinesSvg} alt="" />
                                                <div className='edit-file-left-side-img-block'>
                                                    {type === 'pdf' && <img className='edit-file-image-mobile' src={pdfIconSvg} alt="" />}
                                                    {type === 'video' && <img className='edit-file-image-mobile' width={40} src={editVideoIconSvg} alt="" />}
                                                    {type === 'image' && <img className='edit-list-files-img' src={typeof (item.content.image) === 'string' ? item.content.image : URL.createObjectURL(item.content)} alt="" />}
                                                </div>
                                                <div className='edit-file-left-side-info-block'>
                                                    <span className='edit-file-left-side-info-name'>{title ? title : item.content.name}</span>
                                                    <span className='edit-file-left-side-info-size'>{item.size} KB</span>
                                                </div>
                                            </div>
                                            <Dropdown className='dropdown-edit-list'>
                                                <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                                                    <IoMdMore className='cursor-pointer' size={25} fill='#334155' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleChangeClick(item.id)} className='bootstrap-default-item bootstrap-bold-item'>
                                                        {t("change")}
                                                    </Dropdown.Item>
                                                    {(filesList.length > 1 || allowDeleteLast) && (
                                                        <Dropdown.Item onClick={() => handleDeleteCode(item.id)} className='bootstrap-default-item bootstrap-bold-item'>
                                                            {t("delete")}
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <div className='edit-file-right-side-block'>
                                                <button onClick={() => handleChangeClick(item.id)} className='edit-file-right-side-change-btn'>{t("change")}</button>
                                                {(filesList.length > 1 || allowDeleteLast) && (
                                                    <button onClick={() => handleDeleteCode(item.id)} className='edit-file-right-side-delete-btn'>
                                                        <img src={editCodeDeleteSvg} alt="" />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <input accept={acceptList} onChange={(e) => handleChangeFile(e)} multiple={true} ref={uploadInputRef} type="file" className='hidden' />
        </>
    )
}

export default EditListFilesBlock