import React from 'react'

const WebsitePreview = ({ data }) => {
    return (
        <div className='dynamic-website-wrapper'>
            <input readOnly value={data.website} className='dynamic-website-input' placeholder='Site URL' />
            <div className='dynamic-grey-image-block'></div>
            <div className='dynamic-website-grey-lines-block'>
                <div className='dynamic-website-grey-line-full'></div>
                <div className='dynamic-website-grey-line-full'></div>
                <div className='dynamic-website-grey-line-half'></div>
            </div>
            <div className='dynamic-website-nested-grey-lines-block'>
                <div className='dynamic-website-nested-dark-line'></div>
            </div>
            <div className='dynamic-website-circle-blocks'>
                <div className='dynamic-website-circle-block'>
                    <div className='dynamic-website-circle'></div>
                    <div className='dynamic-website-circle-underline'></div>
                </div>
                <div className='dynamic-website-circle-block'>
                    <div className='dynamic-website-circle'></div>
                    <div className='dynamic-website-circle-underline'></div>
                </div>
                <div className='dynamic-website-circle-block'>
                    <div className='dynamic-website-circle'></div>
                    <div className='dynamic-website-circle-underline'></div>
                </div>
            </div>
        </div>
    )
}

export default WebsitePreview