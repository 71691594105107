import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import confirmArchive from '../../assets/icons/confirm-archive.svg'

const ArchiveConfrimModal = ({ show, onClose, handleArchiveCodeConfirmed, archiveId, setOverviewCode }) => {
    const { t } = useTranslation()

    const handleArchiveCode = () => {
        handleArchiveCodeConfirmed()
        setOverviewCode(null)
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            centered
            position='center'
            onClose={onClose}
            className='modal-please-signup'
        >
            <Modal.Body className='confirm-archive-modal-body'>
                <img className='confirm-modal-icon' src={confirmArchive} alt="" />
                <h5 className='confrim-modal-title'>
                    {t("areYouSureYouWantTo")} {archiveId.type === 'static' && archiveId.archived ? t("unpause") : archiveId.type === 'static' && !archiveId.archived ? t("pause") : archiveId.type === 'dynamic' && archiveId.data.paused ? t("unpause") : t("pause")} {t("tracking")}?
                </h5>
                <div className='confirm-modal-actions-block'>
                    <button onClick={onClose} className='confirm-modal-action-cancel'>{t("cancel")}</button>
                    <button onClick={handleArchiveCode} className='confirm-modal-action-confirm'>
                        {archiveId.type === 'static' && archiveId.archived ? t("unpauseTracking") : archiveId.type === 'static' && !archiveId.archived ? t("pauseTracking") : archiveId.type === 'dynamic' && archiveId.data.paused ? t("unpauseTracking") : t("pauseTracking")}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ArchiveConfrimModal