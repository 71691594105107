// User Creation Flow images
import CodeCreateDefaultPt from '../assets/icons/code-create/default-pt.svg'
import CodeCreateDefaultFr from '../assets/icons/code-create/default-fr.svg'
import CodeCreateDefaultDk from '../assets/icons/code-create/default-dk.svg'
import CodeCreateDefaultDe from '../assets/icons/code-create/default-de.svg'
import CodeCreateDefaultEs from '../assets/icons/code-create/default-es.svg'
import CodeCreateDefaultIt from '../assets/icons/code-create/default-it.svg'
import CodeCreateDefaultHu from '../assets/icons/code-create/default-hu.svg'
import CodeCreateWebsitePt from '../assets/icons/code-create/website-pt.svg'
import CodeCreateWebsiteEs from '../assets/icons/code-create/website-es.svg'
import CodeCreateWebsiteDe from '../assets/icons/code-create/website-de.svg'
import CodeCreateWebsiteFr from '../assets/icons/code-create/website-fr.svg'
import CodeCreateWebsiteDk from '../assets/icons/code-create/website-dk.svg'
import CodeCreateWebsiteIt from '../assets/icons/code-create/website-it.svg'
import CodeCreateWebsiteHu from '../assets/icons/code-create/website-hu.svg'
import CodeCreateImagePt from '../assets/icons/code-create/image-pt.png'
import CodeCreateImageDe from '../assets/icons/code-create/image-de.png'
import CodeCreateImageEs from '../assets/icons/code-create/image-es.png'
import CodeCreateImageFr from '../assets/icons/code-create/image-fr.png'
import CodeCreateImageDk from '../assets/icons/code-create/image-dk.png'
import CodeCreateImageIt from '../assets/icons/code-create/image-it.png'
import CodeCreateImageHu from '../assets/icons/code-create/image-hu.png'
import CodeCreateVideoPt from '../assets/icons/code-create/video-pt.png'
import CodeCreateVideoDe from '../assets/icons/code-create/video-de.png'
import CodeCreateVideoEs from '../assets/icons/code-create/video-es.png'
import CodeCreateVideoFr from '../assets/icons/code-create/video-fr.png'
import CodeCreateVideoDk from '../assets/icons/code-create/video-dk.png'
import CodeCreateVideoIt from '../assets/icons/code-create/video-it.png'
import CodeCreateVideoHu from '../assets/icons/code-create/video-hu.png'
import CodeCreateDefault from '../assets/icons/code-create/default.svg'
import CodeCreateWebsite from '../assets/icons/code-create/website.svg'
import CodeCreateTextPt from '../assets/icons/code-create/text-pt.svg'
import CodeCreateTextDe from '../assets/icons/code-create/text-de.svg'
import CodeCreateTextEs from '../assets/icons/code-create/text-es.svg'
import CodeCreateTextFr from '../assets/icons/code-create/text-fr.svg'
import CodeCreateTextDk from '../assets/icons/code-create/text-dk.svg'
import CodeCreateTextIt from '../assets/icons/code-create/text-it.svg'
import CodeCreateTextHu from '../assets/icons/code-create/text-hu.svg'
import CodeCreateWifiPt from '../assets/icons/code-create/wifi-pt.png'
import CodeCreateWifiDe from '../assets/icons/code-create/wifi-de.png'
import CodeCreateWifiEs from '../assets/icons/code-create/wifi-es.png'
import CodeCreateWifiFr from '../assets/icons/code-create/wifi-fr.png'
import CodeCreateWifiDk from '../assets/icons/code-create/wifi-dk.png'
import CodeCreateWifiIt from '../assets/icons/code-create/wifi-it.png'
import CodeCreateWifiHu from '../assets/icons/code-create/wifi-hu.png'
import CodeCreatePdfPt from '../assets/icons/code-create/pdf-pt.png'
import CodeCreatePdfDe from '../assets/icons/code-create/pdf-de.png'
import CodeCreatePdfEs from '../assets/icons/code-create/pdf-es.png'
import CodeCreatePdfFr from '../assets/icons/code-create/pdf-fr.png'
import CodeCreatePdfDk from '../assets/icons/code-create/pdf-dk.png'
import CodeCreatePdfIt from '../assets/icons/code-create/pdf-it.png'
import CodeCreatePdfHu from '../assets/icons/code-create/pdf-hu.png'
import CodeCreateMp3Pt from '../assets/icons/code-create/mp3-pt.png'
import CodeCreateMp3De from '../assets/icons/code-create/mp3-de.png'
import CodeCreateMp3Es from '../assets/icons/code-create/mp3-es.png'
import CodeCreateMp3Fr from '../assets/icons/code-create/mp3-fr.png'
import CodeCreateMp3Dk from '../assets/icons/code-create/mp3-dk.png'
import CodeCreateMp3It from '../assets/icons/code-create/mp3-it.png'
import CodeCreateMp3Hu from '../assets/icons/code-create/mp3-hu.png'
import CodeCreateImage from '../assets/icons/code-create/image.png'
import CodeCreateVideo from '../assets/icons/code-create/video.png'
import CodeCreateText from '../assets/icons/code-create/text.svg'
import CodeCreateWifi from '../assets/icons/code-create/wifi.png'
import CodeCreateMp3 from '../assets/icons/code-create/mp3.png'
import CodeCreatePdf from '../assets/icons/code-create/pdf.png'

// Onboarding Modal Welcome images
import OnboardingWelcomeMobilePt from '../assets/icons/onboarding/welcome-mobile-pt.svg'
import OnboardingWelcomeMobileDe from '../assets/icons/onboarding/welcome-mobile-de.svg'
import OnboardingWelcomeMobileEs from '../assets/icons/onboarding/welcome-mobile-es.svg'
import OnboardingWelcomeMobileFr from '../assets/icons/onboarding/welcome-mobile-fr.svg'
import OnboardingWelcomeMobileDk from '../assets/icons/onboarding/welcome-mobile-dk.svg'
import OnboardingWelcomeMobileIt from '../assets/icons/onboarding/welcome-mobile-it.svg'
import OnboardingWelcomeMobileHu from '../assets/icons/onboarding/welcome-mobile-hu.svg'
import OnboardingWelcomeMobile from '../assets/icons/onboarding/welcome-mobile.svg'
import OnboardingWelcomePt from '../assets/icons/onboarding/welcome-pt.svg'
import OnboardingWelcomeDe from '../assets/icons/onboarding/welcome-de.svg'
import OnboardingWelcomeEs from '../assets/icons/onboarding/welcome-es.svg'
import OnboardingWelcomeFr from '../assets/icons/onboarding/welcome-fr.svg'
import OnboardingWelcomeDk from '../assets/icons/onboarding/welcome-dk.svg'
import OnboardingWelcomeIt from '../assets/icons/onboarding/welcome-it.svg'
import OnboardingWelcomeHu from '../assets/icons/onboarding/welcome-hu.svg'
import OnboardingTrendPt from '../assets/icons/onboarding/trend-pt.svg'
import OnboardingTrendDe from '../assets/icons/onboarding/trend-de.svg'
import OnboardingTrendEs from '../assets/icons/onboarding/trend-es.svg'
import OnboardingTrendFr from '../assets/icons/onboarding/trend-fr.svg'
import OnboardingTrendDk from '../assets/icons/onboarding/trend-dk.svg'
import OnboardingTrendIt from '../assets/icons/onboarding/trend-it.svg'
import OnboardingTrendHu from '../assets/icons/onboarding/trend-hu.svg'
import OnboardingWelcome from '../assets/icons/onboarding/welcome.svg'
import OnboardingTrend from '../assets/icons/onboarding/trend.svg'

// Onboarding Modal Second Step images
import OnboardingTrackingMobilePt from '../assets/icons/onboarding/tracking-mobile-pt.svg'
import OnboardingTrackingMobileDe from '../assets/icons/onboarding/tracking-mobile-de.svg'
import OnboardingTrackingMobileEs from '../assets/icons/onboarding/tracking-mobile-es.svg'
import OnboardingTrackingMobileFr from '../assets/icons/onboarding/tracking-mobile-fr.svg'
import OnboardingTrackingMobileDk from '../assets/icons/onboarding/tracking-mobile-dk.svg'
import OnboardingTrackingMobileIt from '../assets/icons/onboarding/tracking-mobile-it.svg'
import OnboardingTrackingMobileHu from '../assets/icons/onboarding/tracking-mobile-hu.svg'
import OnboardingTrackingMobile from '../assets/icons/onboarding/tracking-mobile.svg'
import OnboardingGreyCodePt from '../assets/icons/onboarding/grey-code-pt.svg'
import OnboardingGreyCodeDe from '../assets/icons/onboarding/grey-code-de.svg'
import OnboardingGreyCodeEs from '../assets/icons/onboarding/grey-code-es.svg'
import OnboardingGreyCodeFr from '../assets/icons/onboarding/grey-code-fr.svg'
import OnboardingGreyCodeDk from '../assets/icons/onboarding/grey-code-dk.svg'
import OnboardingGreyCodeIt from '../assets/icons/onboarding/grey-code-it.svg'
import OnboardingGreyCodeHu from '../assets/icons/onboarding/grey-code-hu.svg'
import OnboardingTrackingPt from '../assets/icons/onboarding/tracking-pt.svg'
import OnboardingTrackingDe from '../assets/icons/onboarding/tracking-de.svg'
import OnboardingTrackingEs from '../assets/icons/onboarding/tracking-es.svg'
import OnboardingTrackingFr from '../assets/icons/onboarding/tracking-fr.svg'
import OnboardingTrackingDk from '../assets/icons/onboarding/tracking-dk.svg'
import OnboardingTrackingIt from '../assets/icons/onboarding/tracking-it.svg'
import OnboardingTrackingHu from '../assets/icons/onboarding/tracking-hu.svg'
import OnboardingGreyCode from '../assets/icons/onboarding/grey-code.svg'
import OnboardingTracking from '../assets/icons/onboarding/tracking.svg'

// Onboarding Carousel Preview images
import OnboardingImagePreviewPt from '../assets/icons/onboarding/image-preview-pt.svg'
import OnboardingImagePreviewEs from '../assets/icons/onboarding/image-preview-es.svg'
import OnboardingImagePreviewFr from '../assets/icons/onboarding/image-preview-fr.svg'
import OnboardingImagePreviewDk from '../assets/icons/onboarding/image-preview-dk.svg'
import OnboardingImagePreviewDe from '../assets/icons/onboarding/image-preview-de.svg'
import OnboardingImagePreviewIt from '../assets/icons/onboarding/image-preview-it.svg'
import OnboardingImagePreviewHu from '../assets/icons/onboarding/image-preview-hu.png'
import OnboardingVideoPreviewPt from '../assets/icons/onboarding/video-preview-pt.svg'
import OnboardingVideoPreviewEs from '../assets/icons/onboarding/video-preview-es.svg'
import OnboardingVideoPreviewFr from '../assets/icons/onboarding/video-preview-fr.svg'
import OnboardingVideoPreviewDk from '../assets/icons/onboarding/video-preview-dk.svg'
import OnboardingVideoPreviewDe from '../assets/icons/onboarding/video-preview-de.svg'
import OnboardingVideoPreviewIt from '../assets/icons/onboarding/video-preview-it.svg'
import OnboardingVideoPreviewHu from '../assets/icons/onboarding/video-preview-hu.png'
import OnboardingWifiPreviewDe from '../assets/icons/onboarding/wifi-preview-de.png'
import OnboardingWifiPreviewFr from '../assets/icons/onboarding/wifi-preview-fr.png'
import OnboardingWifiPreviewDk from '../assets/icons/onboarding/wifi-preview-dk.png'
import OnboardingWifiPreviewPt from '../assets/icons/onboarding/wifi-preview-pt.png'
import OnboardingWifiPreviewEs from '../assets/icons/onboarding/wifi-preview-es.png'
import OnboardingWifiPreviewIt from '../assets/icons/onboarding/wifi-preview-it.png'
import OnboardingWifiPreviewHu from '../assets/icons/onboarding/wifi-preview-hu.png'
import OnboardingTextPreviewDe from '../assets/icons/onboarding/text-preview-de.svg'
import OnboardingTextPreviewEs from '../assets/icons/onboarding/text-preview-es.svg'
import OnboardingTextPreviewFr from '../assets/icons/onboarding/text-preview-fr.svg'
import OnboardingTextPreviewDk from '../assets/icons/onboarding/text-preview-dk.svg'
import OnboardingTextPreviewIt from '../assets/icons/onboarding/text-preview-it.svg'
import OnboardingTextPreviewPt from '../assets/icons/onboarding/text-preview-pt.svg'
import OnboardingTextPreviewHu from '../assets/icons/onboarding/text-preview-hu.svg'
import OnboardingPdfPreviewDe from '../assets/icons/onboarding/pdf-preview-de.png'
import OnboardingPdfPreviewFr from '../assets/icons/onboarding/pdf-preview-fr.png'
import OnboardingPdfPreviewPt from '../assets/icons/onboarding/pdf-preview-pt.png'
import OnboardingPdfPreviewEs from '../assets/icons/onboarding/pdf-preview-es.png'
import OnboardingPdfPreviewDk from '../assets/icons/onboarding/pdf-preview-dk.png'
import OnboardingPdfPreviewIt from '../assets/icons/onboarding/pdf-preview-it.png'
import OnboardingPdfPreviewHu from '../assets/icons/onboarding/pdf-preview-hu.png'
import OnboardingMp3PreviewDe from '../assets/icons/onboarding/mp3-preview-de.png'
import OnboardingMp3PreviewEs from '../assets/icons/onboarding/mp3-preview-es.png'
import OnboardingMp3PreviewFr from '../assets/icons/onboarding/mp3-preview-fr.png'
import OnboardingMp3PreviewDk from '../assets/icons/onboarding/mp3-preview-dk.png'
import OnboardingMp3PreviewPt from '../assets/icons/onboarding/mp3-preview-pt.png'
import OnboardingMp3PreviewIt from '../assets/icons/onboarding/mp3-preview-it.png'
import OnboardingMp3PreviewHu from '../assets/icons/onboarding/mp3-preview-hu.png'
import OnboardingVideoPreview from '../assets/icons/onboarding/video-preview.svg'
import OnboardingImagePreview from '../assets/icons/onboarding/image-preview.svg'
import OnboardingTextPreview from '../assets/icons/onboarding/text-preview.svg'
import OnboardingWifiPreview from '../assets/icons/onboarding/wifi-preview.png'
import OnboardingPdfPreview from '../assets/icons/onboarding/pdf-preview.png'
import OnboardingMp3Preview from '../assets/icons/onboarding/mp3-preview.png'

// Stripe Powered by image
import PoweredByStripePt from '../assets/images/powered-by-stripe-pt.png'
import PoweredByStripe from '../assets/icons/powered-by-stripe.png'

// Landing page introducing section images
import homeMobileIntroducingPtPng from '../assets/images/home-mobile-introducing-pt.webp'
import homeMobileIntroducingDePng from '../assets/images/home-mobile-introducing-de.webp'
import homeMobileIntroducingEsSvg from '../assets/images/home-mobile-introducing-es.webp'
import homeMobileIntroducingFrSvg from '../assets/images/home-mobile-introducing-fr.webp'
import homeMobileIntroducingDkPng from '../assets/images/home-mobile-introducing-dk.webp'
import homeMobileIntroducingItPng from '../assets/images/home-mobile-introducing-it.webp'
import homeMobileIntroducingHuPng from '../assets/images/home-mobile-introducing-hu.webp'
import homeMobileIntroducingSvg from '../assets/images/home-mobile-introducing.webp'
import homeNotificationDePng from '../assets/images/home-notification-de.png'
import homeNotificationPtPng from '../assets/images/home-notification-pt.png'
import homeNotificationEsSvg from '../assets/images/home-notification-es.png'
import homeNotificationFrSvg from '../assets/images/home-notification-fr.png'
import homeNotificationDkSvg from '../assets/images/home-notification-dk.png'
import homeNotificationItSvg from '../assets/images/home-notification-it.png'
import homeNotificationHuPng from '../assets/images/home-notification-hu.png'
import homeNotificationSvg from '../assets/images/home-notification.png'
import homeDesktopDePng from '../assets/images/home-desktop-de.webp'
import homeDesktopDkPng from '../assets/images/home-desktop-dk.webp'
import homeDesktopEsPng from '../assets/images/home-desktop-es.webp'
import homeDesktopFrPng from '../assets/images/home-desktop-fr.webp'
import homeDesktopPtPng from '../assets/images/home-desktop-pt.webp'
import homeDesktopItPng from '../assets/images/home-desktop-it.webp'
import homeDesktopHuPng from '../assets/images/home-desktop-hu.png'
import homeDesktopSvg from '../assets/images/home-desktop.webp'

// Landing page discover section images
import homeImagePreviewPtPng from '../assets/images/home-image-preview-pt.webp'
import homeImagePreviewEsSvg from '../assets/images/home-image-preview-es.webp'
import homeImagePreviewDePng from '../assets/images/home-image-preview-de.webp'
import homeImagePreviewFrSvg from '../assets/images/home-image-preview-fr.webp'
import homeImagePreviewDkPng from '../assets/images/home-image-preview-dk.webp'
import homeImagePreviewItPng from '../assets/images/home-image-preview-it.webp'
import homeImagePreviewHuPng from '../assets/images/home-image-preview-hu.webp'
import homeVideoPreviewPtPng from '../assets/images/home-video-preview-pt.webp'
import homeVideoPreviewDePng from '../assets/images/home-video-preview-de.webp'
import homeVideoPreviewEsPng from '../assets/images/home-video-preview-es.webp'
import homeVideoPreviewFrPng from '../assets/images/home-video-preview-fr.webp'
import homeVideoPreviewDkPng from '../assets/images/home-video-preview-dk.webp'
import homeVideoPreviewItPng from '../assets/images/home-video-preview-it.webp'
import homeVideoPreviewHuPng from '../assets/images/home-video-preview-hu.webp'
import homeWifiPreviewPtPng from '../assets/images/home-wifi-preview-pt.webp'
import homeWifiPreviewFrPng from '../assets/images/home-wifi-preview-fr.webp'
import homeWifiPreviewEsPng from '../assets/images/home-wifi-preview-es.webp'
import homeWifiPreviewDkPng from '../assets/images/home-wifi-preview-dk.webp'
import homeWifiPreviewDePng from '../assets/images/home-wifi-preview-de.webp'
import homeWifiPreviewItPng from '../assets/images/home-wifi-preview-it.webp'
import homeWifiPreviewHuPng from '../assets/images/home-wifi-preview-hu.webp'
import homeTextPreviewHuPng from '../assets/images/home-text-preview-hu.webp'
import homeTextPreviewPtPng from '../assets/images/home-text-preview-pt.png'
import homeTextPreviewEsPng from '../assets/images/home-text-preview-es.png'
import homeTextPreviewDkPng from '../assets/images/home-text-preview-dk.png'
import homeTextPreviewDePng from '../assets/images/home-text-preview-de.png'
import homeTextPreviewFrPng from '../assets/images/home-text-preview-fr.png'
import homeTextPreviewItPng from '../assets/images/home-text-preview-it.png'
import homeMp3PreviewPtPng from '../assets/images/home-mp3-preview-pt.webp'
import homeMp3PreviewEsPng from '../assets/images/home-mp3-preview-es.webp'
import homeMp3PreviewFrPng from '../assets/images/home-mp3-preview-fr.webp'
import homeMp3PreviewDkPng from '../assets/images/home-mp3-preview-dk.webp'
import homeMp3PreviewDePng from '../assets/images/home-mp3-preview-de.webp'
import homeMp3PreviewItPng from '../assets/images/home-mp3-preview-it.webp'
import homeMp3PreviewHuPng from '../assets/images/home-mp3-preview-hu.webp'
import homePdfPreviewPtPng from '../assets/images/home-pdf-preview-pt.webp'
import homePdfPreviewEsPng from '../assets/images/home-pdf-preview-es.webp'
import homePdfPreviewFrPng from '../assets/images/home-pdf-preview-fr.webp'
import homePdfPreviewDePng from '../assets/images/home-pdf-preview-de.webp'
import homePdfPreviewDkPng from '../assets/images/home-pdf-preview-dk.webp'
import homePdfPreviewItPng from '../assets/images/home-pdf-preview-it.webp'
import homePdfPreviewHuPng from '../assets/images/home-pdf-preview-hu.webp'
import homeUrlPreviewDePng from '../assets/images/home-url-preview-de.png'
import homeUrlPreviewPtPng from '../assets/images/home-url-preview-pt.png'
import homeUrlPreviewEsPng from '../assets/images/home-url-preview-es.png'
import homeUrlPreviewFrPng from '../assets/images/home-url-preview-fr.png'
import homeUrlPreviewDkPng from '../assets/images/home-url-preview-dk.png'
import homeUrlPreviewItPng from '../assets/images/home-url-preview-it.png'
import homeUrlPreviewHuPng from '../assets/images/home-url-preview-hu.png'
import homeImagePreviewSvg from '../assets/images/home-image-preview.webp'
import homeVideoPreviewSvg from '../assets/images/home-video-preview.webp'
import homeWifiPreviewSvg from '../assets/images/home-wifi-preview.webp'
import homeTextPreviewSvg from '../assets/images/home-text-preview.svg'
import homeMp3PreviewSvg from '../assets/images/home-mp3-preview.webp'
import homePdfPreviewSvg from '../assets/images/home-pdf-preview.webp'
import homeUrlPreviewSvg from '../assets/images/home-url-preview.png'

// Landing page discover section images translations based on the language
export const LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS = {
    'en': {
        'url': homeUrlPreviewSvg,
        'text': homeTextPreviewSvg,
        'wi-fi': homeWifiPreviewSvg,
        'pdf': homePdfPreviewSvg,
        'video': homeVideoPreviewSvg,
        'mp3': homeMp3PreviewSvg,
        'image': homeImagePreviewSvg,
    },
    'pt': {
        'url': homeUrlPreviewPtPng,
        'text': homeTextPreviewPtPng,
        'wi-fi': homeWifiPreviewPtPng,
        'pdf': homePdfPreviewPtPng,
        'video': homeVideoPreviewPtPng,
        'mp3': homeMp3PreviewPtPng,
        'image': homeImagePreviewPtPng,
    },
    'de': {
        'url': homeUrlPreviewDePng,
        'text': homeTextPreviewDePng,
        'wi-fi': homeWifiPreviewDePng,
        'pdf': homePdfPreviewDePng,
        'video': homeVideoPreviewDePng,
        'mp3': homeMp3PreviewDePng,
        'image': homeImagePreviewDePng,
    },
    'es': {
        'url': homeUrlPreviewEsPng,
        'text': homeTextPreviewEsPng,
        'wi-fi': homeWifiPreviewEsPng,
        'pdf': homePdfPreviewEsPng,
        'video': homeVideoPreviewEsPng,
        'mp3': homeMp3PreviewEsPng,
        'image': homeImagePreviewEsSvg,
    },
    'fr': {
        'url': homeUrlPreviewFrPng,
        'text': homeTextPreviewFrPng,
        'wi-fi': homeWifiPreviewFrPng,
        'pdf': homePdfPreviewFrPng,
        'video': homeVideoPreviewFrPng,
        'mp3': homeMp3PreviewFrPng,
        'image': homeImagePreviewFrSvg,
    },
    'dk': {
        'url': homeUrlPreviewDkPng,
        'text': homeTextPreviewDkPng,
        'wi-fi': homeWifiPreviewDkPng,
        'pdf': homePdfPreviewDkPng,
        'video': homeVideoPreviewDkPng,
        'mp3': homeMp3PreviewDkPng,
        'image': homeImagePreviewDkPng,
    },
    'it': {
        'url': homeUrlPreviewItPng,
        'text': homeTextPreviewItPng,
        'wi-fi': homeWifiPreviewItPng,
        'pdf': homePdfPreviewItPng,
        'video': homeVideoPreviewItPng,
        'mp3': homeMp3PreviewItPng,
        'image': homeImagePreviewItPng
    },
    'hu': {
        'url': homeUrlPreviewHuPng,
        'text': homeTextPreviewHuPng,
        'wi-fi': homeWifiPreviewHuPng,
        'pdf': homePdfPreviewHuPng,
        'video': homeVideoPreviewHuPng,
        'mp3': homeMp3PreviewHuPng,
        'image': homeImagePreviewHuPng
    },
    'mx': {
        'url': homeUrlPreviewEsPng,
        'text': homeTextPreviewEsPng,
        'wi-fi': homeWifiPreviewEsPng,
        'pdf': homePdfPreviewEsPng,
        'video': homeVideoPreviewEsPng,
        'mp3': homeMp3PreviewEsPng,
        'image': homeImagePreviewEsSvg,
    },
    'portugal': {
        'url': homeUrlPreviewPtPng,
        'text': homeTextPreviewPtPng,
        'wi-fi': homeWifiPreviewPtPng,
        'pdf': homePdfPreviewPtPng,
        'video': homeVideoPreviewPtPng,
        'mp3': homeMp3PreviewPtPng,
        'image': homeImagePreviewPtPng,
    }
}

// Landing page introducing section images translations based on the language
export const LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS = {
    'en': {
        'mobile': homeMobileIntroducingSvg,
        'desktop': homeDesktopSvg,
        'notification': homeNotificationSvg,
    },
    'pt': {
        'mobile': homeMobileIntroducingPtPng,
        'desktop': homeDesktopPtPng,
        'notification': homeNotificationPtPng,
    },
    'de': {
        'mobile': homeMobileIntroducingDePng,
        'desktop': homeDesktopDePng,
        'notification': homeNotificationDePng,
    },
    'es': {
        'mobile': homeMobileIntroducingEsSvg,
        'desktop': homeDesktopEsPng,
        'notification': homeNotificationEsSvg,
    },
    'fr': {
        'mobile': homeMobileIntroducingFrSvg,
        'desktop': homeDesktopFrPng,
        'notification': homeNotificationFrSvg,
    },
    'dk': {
        'mobile': homeMobileIntroducingDkPng,
        'desktop': homeDesktopDkPng,
        'notification': homeNotificationDkSvg,
    },
    'it': {
        'mobile': homeMobileIntroducingItPng,
        'desktop': homeDesktopItPng,
        'notification': homeNotificationItSvg,
    },
    'hu': {
        'mobile': homeMobileIntroducingHuPng,
        'desktop': homeDesktopHuPng,
        'notification': homeNotificationHuPng,
    },
    'mx': {
        'mobile': homeMobileIntroducingEsSvg,
        'desktop': homeDesktopEsPng,
        'notification': homeNotificationEsSvg,
    },
    'portugal': {
        'mobile': homeMobileIntroducingPtPng,
        'desktop': homeDesktopPtPng,
        'notification': homeNotificationPtPng,
    }
}

// Powered by Stripe images translations based on the language
export const POWERED_BY_STRIPE_IMAGES_TRANSLATIONS = {
    'en': PoweredByStripe,
    'pt': PoweredByStripePt,
    'de': PoweredByStripe,
    'es': PoweredByStripe,
    'fr': PoweredByStripe,
    'dk': PoweredByStripe,
    'it': PoweredByStripe,
    'hu': PoweredByStripe,
    'mx': PoweredByStripe,
    'portugal': PoweredByStripePt,
}

// User Creation Flow images translation based on the language
export const USER_CREATE_CODE_IMAGES_TRANSLATIONS = {
    'en': {
        'URL': CodeCreateWebsite,
        'Text': CodeCreateText,
        'Wi-Fi': CodeCreateWifi,
        'Image': CodeCreateImage,
        'PDF': CodeCreatePdf,
        'Video': CodeCreateVideo,
        'MP3': CodeCreateMp3,
        'DEFAULT': CodeCreateDefault,
    },
    'pt': {
        'URL': CodeCreateWebsitePt,
        'Text': CodeCreateTextPt,
        'Wi-Fi': CodeCreateWifiPt,
        'Image': CodeCreateImagePt,
        'PDF': CodeCreatePdfPt,
        'Video': CodeCreateVideoPt,
        'MP3': CodeCreateMp3Pt,
        'DEFAULT': CodeCreateDefaultPt,
    },
    'de': {
        'URL': CodeCreateWebsiteDe,
        'Text': CodeCreateTextDe,
        'Wi-Fi': CodeCreateWifiDe,
        'Image': CodeCreateImageDe,
        'PDF': CodeCreatePdfDe,
        'Video': CodeCreateVideoDe,
        'MP3': CodeCreateMp3De,
        'DEFAULT': CodeCreateDefaultDe,
    },
    'es': {
        'URL': CodeCreateWebsiteEs,
        'Text': CodeCreateTextEs,
        'Wi-Fi': CodeCreateWifiEs,
        'Image': CodeCreateImageEs,
        'PDF': CodeCreatePdfEs,
        'Video': CodeCreateVideoEs,
        'MP3': CodeCreateMp3Es,
        'DEFAULT': CodeCreateDefaultEs,
    },
    'fr': {
        'URL': CodeCreateWebsiteFr,
        'Text': CodeCreateTextFr,
        'Wi-Fi': CodeCreateWifiFr,
        'Image': CodeCreateImageFr,
        'PDF': CodeCreatePdfFr,
        'Video': CodeCreateVideoFr,
        'MP3': CodeCreateMp3Fr,
        'DEFAULT': CodeCreateDefaultFr,
    },
    'dk': {
        'URL': CodeCreateWebsiteDk,
        'Text': CodeCreateTextDk,
        'Wi-Fi': CodeCreateWifiDk,
        'Image': CodeCreateImageDk,
        'PDF': CodeCreatePdfDk,
        'Video': CodeCreateVideoDk,
        'MP3': CodeCreateMp3Dk,
        'DEFAULT': CodeCreateDefaultDk,
    },
    'it': {
        'URL': CodeCreateWebsiteIt,
        'Text': CodeCreateTextIt,
        'Wi-Fi': CodeCreateWifiIt,
        'Image': CodeCreateImageIt,
        'PDF': CodeCreatePdfIt,
        'Video': CodeCreateVideoIt,
        'MP3': CodeCreateMp3It,
        'DEFAULT': CodeCreateDefaultIt,
    },
    'hu': {
        'DEFAULT': CodeCreateDefaultHu,
        'URL': CodeCreateWebsiteHu,
        'Text': CodeCreateTextHu,
        'Wi-Fi': CodeCreateWifiHu,
        'Image': CodeCreateImageHu,
        'PDF': CodeCreatePdfHu,
        'Video': CodeCreateVideoHu,
        'MP3': CodeCreateMp3Hu,
    },
    'mx': {
        'URL': CodeCreateWebsiteEs,
        'Text': CodeCreateTextEs,
        'Wi-Fi': CodeCreateWifiEs,
        'Image': CodeCreateImageEs,
        'PDF': CodeCreatePdfEs,
        'Video': CodeCreateVideoEs,
        'MP3': CodeCreateMp3Es,
        'DEFAULT': CodeCreateDefaultEs,
    },
    'portugal': {
        'URL': CodeCreateWebsitePt,
        'Text': CodeCreateTextPt,
        'Wi-Fi': CodeCreateWifiPt,
        'Image': CodeCreateImagePt,
        'PDF': CodeCreatePdfPt,
        'Video': CodeCreateVideoPt,
        'MP3': CodeCreateMp3Pt,
        'DEFAULT': CodeCreateDefaultPt,
    }
}

// Onboarding modal welcome images translations based on the language
export const ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS = {
    'en': {
        'mobile': OnboardingWelcomeMobile,
        'desktop': OnboardingWelcome,
        'trend': OnboardingTrend,
    },
    'pt': {
        'mobile': OnboardingWelcomeMobilePt,
        'desktop': OnboardingWelcomePt,
        'trend': OnboardingTrendPt,
    },
    'de': {
        'mobile': OnboardingWelcomeMobileDe,
        'desktop': OnboardingWelcomeDe,
        'trend': OnboardingTrendDe,
    },
    'es': {
        'mobile': OnboardingWelcomeMobileEs,
        'desktop': OnboardingWelcomeEs,
        'trend': OnboardingTrendEs,
    },
    'fr': {
        'mobile': OnboardingWelcomeMobileFr,
        'desktop': OnboardingWelcomeFr,
        'trend': OnboardingTrendFr,
    },
    'dk': {
        'mobile': OnboardingWelcomeMobileDk,
        'desktop': OnboardingWelcomeDk,
        'trend': OnboardingTrendDk,
    },
    'it': {
        'mobile': OnboardingWelcomeMobileIt,
        'desktop': OnboardingWelcomeIt,
        'trend': OnboardingTrendIt,
    },
    'hu': {
        'mobile': OnboardingWelcomeMobileHu,
        'desktop': OnboardingWelcomeHu,
        'trend': OnboardingTrendHu,
    },
    'mx': {
        'mobile': OnboardingWelcomeMobileEs,
        'desktop': OnboardingWelcomeEs,
        'trend': OnboardingTrendEs,
    },
    'portugal': {
        'mobile': OnboardingWelcomeMobilePt,
        'desktop': OnboardingWelcomePt,
        'trend': OnboardingTrendPt,
    }
}

// Onboarding modal second step images translations based on the language
export const ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS = {
    'en': {
        'mobile': OnboardingTrackingMobile,
        'desktop': OnboardingTracking,
        'code': OnboardingGreyCode,
    },
    'pt': {
        'mobile': OnboardingTrackingMobilePt,
        'desktop': OnboardingTrackingPt,
        'code': OnboardingGreyCodePt,
    },
    'de': {
        'mobile': OnboardingTrackingMobileDe,
        'desktop': OnboardingTrackingDe,
        'code': OnboardingGreyCodeDe,
    },
    'es': {
        'mobile': OnboardingTrackingMobileEs,
        'desktop': OnboardingTrackingEs,
        'code': OnboardingGreyCodeEs,
    },
    'fr': {
        'mobile': OnboardingTrackingMobileFr,
        'desktop': OnboardingTrackingFr,
        'code': OnboardingGreyCodeFr,
    },
    'dk': {
        'mobile': OnboardingTrackingMobileDk,
        'desktop': OnboardingTrackingDk,
        'code': OnboardingGreyCodeDk,
    },
    'it': {
        'mobile': OnboardingTrackingMobileIt,
        'desktop': OnboardingTrackingIt,
        'code': OnboardingGreyCodeIt,
    },
    'hu': {
        'mobile': OnboardingTrackingMobileHu,
        'desktop': OnboardingTrackingHu,
        'code': OnboardingGreyCodeHu,
    },
    'mx': {
        'mobile': OnboardingTrackingMobileEs,
        'desktop': OnboardingTrackingEs,
        'code': OnboardingGreyCodeEs,
    },
    'portugal': {
        'mobile': OnboardingTrackingMobilePt,
        'desktop': OnboardingTrackingPt,
        'code': OnboardingGreyCodePt,
    }
}

// Onboarding carousel preview images translations based on the language
export const ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS = {
    'en': {
        'video': OnboardingVideoPreview,
        'text': OnboardingTextPreview,
        'wi-fi': OnboardingWifiPreview,
        'mp3': OnboardingMp3Preview,
        'pdf': OnboardingPdfPreview,
        'photos': OnboardingImagePreview,
    },
    'pt': {
        'video': OnboardingVideoPreviewPt,
        'text': OnboardingTextPreviewPt,
        'wi-fi': OnboardingWifiPreviewPt,
        'mp3': OnboardingMp3PreviewPt,
        'pdf': OnboardingPdfPreviewPt,
        'photos': OnboardingImagePreviewPt,
    },
    'de': {
        'video': OnboardingVideoPreviewDe,
        'text': OnboardingTextPreviewDe,
        'wi-fi': OnboardingWifiPreviewDe,
        'mp3': OnboardingMp3PreviewDe,
        'pdf': OnboardingPdfPreviewDe,
        'photos': OnboardingImagePreviewDe,
    },
    'es': {
        'video': OnboardingVideoPreviewEs,
        'text': OnboardingTextPreviewEs,
        'wi-fi': OnboardingWifiPreviewEs,
        'mp3': OnboardingMp3PreviewEs,
        'pdf': OnboardingPdfPreviewEs,
        'photos': OnboardingImagePreviewEs,
    },
    'fr': {
        'video': OnboardingVideoPreviewFr,
        'text': OnboardingTextPreviewFr,
        'wi-fi': OnboardingWifiPreviewFr,
        'mp3': OnboardingMp3PreviewFr,
        'pdf': OnboardingPdfPreviewFr,
        'photos': OnboardingImagePreviewFr,
    },
    'dk': {
        'video': OnboardingVideoPreviewDk,
        'text': OnboardingTextPreviewDk,
        'wi-fi': OnboardingWifiPreviewDk,
        'mp3': OnboardingMp3PreviewDk,
        'pdf': OnboardingPdfPreviewDk,
        'photos': OnboardingImagePreviewDk
    },
    'it': {
        'video': OnboardingVideoPreviewIt,
        'text': OnboardingTextPreviewIt,
        'wi-fi': OnboardingWifiPreviewIt,
        'mp3': OnboardingMp3PreviewIt,
        'pdf': OnboardingPdfPreviewIt,
        'photos': OnboardingImagePreviewIt
    },
    'hu': {
        'video': OnboardingVideoPreviewHu,
        'text': OnboardingTextPreviewHu,
        'wi-fi': OnboardingWifiPreviewHu,
        'mp3': OnboardingMp3PreviewHu,
        'pdf': OnboardingPdfPreviewHu,
        'photos': OnboardingImagePreviewHu,
    },
    'mx': {
        'video': OnboardingVideoPreviewEs,
        'text': OnboardingTextPreviewEs,
        'wi-fi': OnboardingWifiPreviewEs,
        'mp3': OnboardingMp3PreviewEs,
        'pdf': OnboardingPdfPreviewEs,
        'photos': OnboardingImagePreviewEs,
    },
    'portugal': {
        'video': OnboardingVideoPreviewPt,
        'text': OnboardingTextPreviewPt,
        'wi-fi': OnboardingWifiPreviewPt,
        'mp3': OnboardingMp3PreviewPt,
        'pdf': OnboardingPdfPreviewPt,
        'photos': OnboardingImagePreviewPt,
    }
}