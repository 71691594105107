import React, { useEffect, useMemo, useRef, useState } from "react";
import useToasts from "../hooks/useToasts";
import { useSelector } from "react-redux";
import rgbHex from 'rgb-hex';

import { useEditDynamicImageCodeMutation, useEditDynamicMp3CodeMutation, useEditDynamicPdfCodeMutation, useEditDynamicTextCodeMutation, useEditDynamicVideoCodeMutation, useEditDynamicWebsiteCodeMutation, useEditDynamicWifiCodeMutation, useUploadImagesDynamicImagesQrCodeMutation } from "../api/api";
import { base64ToBlob, convertQrCodeToImage, fileToBlob } from "../helpers/functions";
import { useCustomNavigate } from "../hooks/useCustomNavigate";
import { COLOR_PALETTE_LIST } from "../helpers/constants";

const EditQrCodeContext = React.createContext();

export const useEditQrCodeContext = () => React.useContext(EditQrCodeContext);

export const EditQrCodeProvider = ({ children }) => {
    const user = useSelector((state) => state.app.user)
    const [type, setType] = useState('website_url')
    const [isGenerating, setIsGenerating] = useState(false)
    const [editCodeData, setEditCodeData] = useState({})
    const [codeName, setCodeName] = useState('')
    const [staticValue, setStaticValue] = useState('')
    const [foregroundColor, setForegroundColor] = useState('#000000')
    const [backgroundColor, setBackgroundColor] = useState('#ffffff')
    const [selectForegroundColor, setSelectForegroundColor] = useState(false)
    const [selectBackgroundColor, setSelectBackgroundColor] = useState(false)
    const [image, setImage] = useState('')
    const [expandEditQrCode, setExpandEditQrCode] = useState(true)
    const [expandEditLanding, setExpandEditLanding] = useState(true)
    const [designPrimaryColor, setDesignPrimaryColor] = useState('')
    const [designSecondaryColor, setDesignSecondaryColor] = useState('')
    const [designTextColor, setDesignTextColor] = useState('')
    const [designButtonColor, setDesignButtonColor] = useState('')
    const [selectDesignPrimaryColor, setSelectDesignPrimaryColor] = useState(false)
    const [selectDesignSecondaryColor, setSelectDesignSecondaryColor] = useState(false)
    const [selectDesignTextColor, setSelectDesignTextColor] = useState(false)
    const [selectDesignButtonColor, setSelectDesignButtonColor] = useState(false)
    const [frame, setFrame] = useState(1)
    const [scanText, setScanText] = useState('')
    const [scanColor, setScanColor] = useState('#000000')
    const [selectScanColor, setSelectScanColor] = useState(false)
    const [scanFont, setScanFont] = useState('Sans-Serif')
    const [canvasStyles, setCanvasStyles] = useState({})
    const [editMobileStep, setEditMobileStep] = useState(1)
    const [selectedColorPalette, setSelectedColorPalette] = useState(null)
    const [dynamicWebsiteStyles, setDynamicWebsiteStyles] = useState({
        website: 'https://'
    })
    const [dynamicPdfStyles, setDynamicPdfStyles] = useState({
        files: [],
        logo: null,
        image: null,
        company: '',
        title: '',
        description: '',
        website: '',
        button: '',
    })
    const [dynamicMP3Styles, setDynamicMP3Styles] = useState({
        file: '',
        logo: null,
        title: '',
        description: '',
        website: '',
        button: '',
        allowDownload: false,
        redirect: '',
    })
    const [dynamicVideoStyles, setDynamicVideoStyles] = useState({
        files: [],
        logo: null,
        link: '',
        showDirectly: false,
        highlightVideo: false,
        autoplayVideo: false,
        company: '',
        title: '',
        description: '',
        button: '',
        website: '',
        redirect: '',
    })
    const [dynamicImageStyles, setDynamicImageStyles] = useState({
        files: [],
        title: '',
        description: '',
        website: '',
        button: '',
        redirect: '',
        deleted: [],
    })
    const [dynamicWifiStyles, setDynamicWifiStyles] = useState({
        network_name: '',
        network_type: 'WEP',
        password: '',
        hidden: false,
        logo: null,
        cover: null,
    })
    const [dynamicTextStyles, setDynamicTextStyles] = useState({
        text: '',
        subject: '',
        company: '',
        logo: null,
    })

    const imageSettings = useMemo(() => {
        return {
            src: image ? image : '',
            height: user ? 34 : 48,
            width: user ? 34 : 48,
            excavate: false,
        }
    }, [image, user]);

    const scanColorRef = useRef(null)
    const canvasCreationRef = useRef(null)
    const designPrimaryColorRef = useRef(null)
    const designSecondaryColorRef = useRef(null)
    const designTextColorRef = useRef(null)
    const designButtonColorRef = useRef(null)

    const { errorToast, editedToast } = useToasts()

    const navigate = useCustomNavigate()

    const [saveEditDynamicWebsiteCode] = useEditDynamicWebsiteCodeMutation()
    const [saveEditDynamicPdfCode] = useEditDynamicPdfCodeMutation()
    const [saveEditDynamicMp3Code] = useEditDynamicMp3CodeMutation()
    const [saveEditDynamicVideoCode] = useEditDynamicVideoCodeMutation()
    const [saveEditDynamicImageCode] = useEditDynamicImageCodeMutation()
    const [saveEditDynamicWifiCode] = useEditDynamicWifiCodeMutation()
    const [saveEditDynamicTextCode] = useEditDynamicTextCodeMutation()
    const [uploadImagesDynamicImagesQrCode] = useUploadImagesDynamicImagesQrCodeMutation()

    const handleChangeForegroundColor = (value) => {
        setForegroundColor("#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a))
    }

    const handleChangeBackgroundColor = (value) => {
        setBackgroundColor("#" + rgbHex(value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a))
    }

    const handleForegroundColor = () => {
        setSelectForegroundColor(true)
        setSelectBackgroundColor(false)
    }

    const handleBackgroundColor = () => {
        setSelectBackgroundColor(true)
        setSelectForegroundColor(false)
    }

    const handleSelectPalette = (palette) => {
        setSelectedColorPalette(palette)
        setDesignPrimaryColor(palette.primary_color)
        setDesignSecondaryColor(palette.secondary_color)
        setDesignTextColor(palette.text_color)
        setDesignButtonColor(palette.button_color)
    }

    const handleSaveEditCode = async () => {
        setIsGenerating(true)

        const url = await convertQrCodeToImage(canvasCreationRef, imageSettings, 4)

        const blobImage = base64ToBlob(url, 'image/png')

        const formData = new FormData()

        formData.append('image', blobImage)

        if (editCodeData?.data?.kind === 'website_url') {
            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        website_url: dynamicWebsiteStyles.website,
                    }
                }
            }

            formData.append('payload', JSON.stringify(newObject))

            saveEditDynamicWebsiteCode({ data: formData, id: newObject.id }).then((res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()
                }
            })
        } else if (editCodeData?.data?.kind === 'pdf') {
            let coverBlob;
            let logoBlob;

            if (dynamicPdfStyles.image) {
                if (typeof (dynamicPdfStyles.image) === 'string') {
                    coverBlob = null
                } else {
                    coverBlob = await fileToBlob(dynamicPdfStyles.image)
                }
            }

            if (dynamicPdfStyles.logo) {
                if (typeof (dynamicPdfStyles.logo) === 'string') {
                    logoBlob = null
                } else {
                    logoBlob = await fileToBlob(dynamicPdfStyles.logo)
                }
            }

            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        title: dynamicPdfStyles.title,
                        description: dynamicPdfStyles.description,
                        company_name: dynamicPdfStyles.company,
                        website: dynamicPdfStyles.website,
                        button_text: dynamicPdfStyles.button,
                    },
                }
            }

            for (const file of Array.from(dynamicPdfStyles.files)) {
                let res;

                if (typeof (file?.file) === 'string') {
                    const result = await fetch(file.file)

                    res = await result.blob()
                } else {
                    res = await fileToBlob(file)
                }

                formData.append('pdf_files', res)
            }

            formData.append('payload', JSON.stringify(newObject))
            formData.append('cover', coverBlob)
            formData.append('logo', logoBlob)

            saveEditDynamicPdfCode({ data: formData, id: newObject.id }).then((res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()
                }
            })
        } else if (editCodeData?.data?.kind === 'mp3') {
            let logoBlob;
            let audioBlob;

            if (dynamicMP3Styles.file) {
                if (typeof (dynamicMP3Styles.file) === 'string') {
                    audioBlob = null
                } else {
                    audioBlob = await fileToBlob(dynamicMP3Styles.file)
                }
            }

            if (dynamicMP3Styles.logo) {
                if (typeof (dynamicMP3Styles.logo) === 'string') {
                    logoBlob = null
                } else {
                    logoBlob = await fileToBlob(dynamicMP3Styles.logo)
                }
            }

            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        title: dynamicMP3Styles.title,
                        description: dynamicMP3Styles.description,
                        button_text: dynamicMP3Styles.button,
                        button_redirect_link: dynamicMP3Styles.redirect,
                        website: dynamicMP3Styles.website,
                        allowDownload: dynamicMP3Styles.allowDownload,
                    },
                }
            }

            formData.append('logo', logoBlob)
            formData.append('mp3_file', audioBlob)
            formData.append('payload', JSON.stringify(newObject))

            saveEditDynamicMp3Code({ data: formData, id: newObject.id }).then((res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()
                }
            })
        } else if (editCodeData?.data?.kind === 'video') {
            let logoBlob;

            if (dynamicVideoStyles.logo) {
                if (typeof (dynamicVideoStyles.logo) === 'string') {
                    logoBlob = null
                } else {
                    logoBlob = await fileToBlob(dynamicVideoStyles.logo)
                }
            }

            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        title: dynamicVideoStyles.title,
                        description: dynamicVideoStyles.description,
                        button_text: dynamicVideoStyles.button,
                        button_redirect_link: dynamicVideoStyles.redirect,
                        website: dynamicVideoStyles.website,
                        video_url: dynamicVideoStyles.link,
                        company_name: dynamicVideoStyles.company,
                        show_directly: dynamicVideoStyles.showDirectly,
                        highlight_first: dynamicVideoStyles.highlightVideo,
                        autoplay_first: dynamicVideoStyles.autoplayVideo,
                    },
                }
            }

            for (const file of Array.from(dynamicVideoStyles.files)) {
                let res;

                if (typeof (file?.file) === 'string') {
                    const result = await fetch(file.file)

                    res = await result.blob()
                } else {
                    res = await fileToBlob(file)
                }

                formData.append('video_files', res)
            }

            formData.append('payload', JSON.stringify(newObject))
            formData.append('logo', logoBlob)

            saveEditDynamicVideoCode({ data: formData, id: newObject.id }).then((res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()
                }
            })
        } else if (editCodeData?.data?.kind === 'images') {
            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        title: dynamicImageStyles.title,
                        description: dynamicImageStyles.description,
                        button_text: dynamicImageStyles.button,
                        button_redirect_link: dynamicImageStyles.redirect,
                        website_url: dynamicImageStyles.website,
                    },
                }
            }

            formData.append('payload', JSON.stringify(newObject))

            saveEditDynamicImageCode({ data: formData, id: newObject.id }).then(async (res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()

                    for (let image of Array.from(dynamicImageStyles.files)) {
                        const imagesFormData = new FormData()
                        let convertedImage;

                        if (!image?.image) {
                            convertedImage = await fileToBlob(image)
                        } else {
                            const response = await fetch(image.image)

                            convertedImage = await response.blob()
                        }

                        imagesFormData.append('image', convertedImage)

                        await uploadImagesDynamicImagesQrCode({ data: imagesFormData, id: newObject.id })
                    }
                }

            })
        } else if (editCodeData?.data?.kind === 'wifi') {
            let logoBlob = null;
            let coverBlob = null;

            if (dynamicWifiStyles.logo) {
                if (typeof (dynamicWifiStyles.logo) === 'string') {
                    const response = await fetch(dynamicWifiStyles.logo)
                    const result = await response.blob()

                    logoBlob = result
                } else {
                    logoBlob = await fileToBlob(dynamicWifiStyles.logo)
                }
            }

            if (dynamicWifiStyles.cover) {
                if (typeof (dynamicWifiStyles.cover) === 'string') {
                    const response = await fetch(dynamicWifiStyles.cover)
                    const result = await response.blob()

                    coverBlob = result
                } else {
                    coverBlob = await fileToBlob(dynamicWifiStyles.cover)
                }
            }

            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        network_name: dynamicWifiStyles.network_name,
                        type: dynamicWifiStyles.network_type,
                        password: dynamicWifiStyles.password,
                        hidden: dynamicWifiStyles.hidden,
                    },
                }
            }

            formData.append('payload', JSON.stringify(newObject))
            formData.append('logo', logoBlob)
            formData.append('cover', coverBlob)

            saveEditDynamicWifiCode({ data: formData, id: newObject.id }).then((res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()
                }
            })
        } else if (editCodeData?.data?.kind === 'text') {
            let logoBlob = null;

            if (dynamicTextStyles.logo) {
                if (typeof (dynamicTextStyles.logo) === 'string') {
                    const response = await fetch(dynamicTextStyles.logo)
                    const result = await response.blob()

                    logoBlob = result
                } else {
                    logoBlob = await fileToBlob(dynamicTextStyles.logo)
                }
            }

            const newObject = {
                ...editCodeData,
                name: codeName,
                styles: {
                    ...editCodeData.styles,
                    background: backgroundColor,
                    foreground: foregroundColor,
                    icon: image,
                    frame: frame,
                    scanColor: scanColor,
                    scanText: scanText,
                    scanFont: scanFont,
                },
                data: {
                    ...editCodeData.data,
                    content: {
                        ...editCodeData.data.content,
                        primary_color: designPrimaryColor,
                        secondary_color: designSecondaryColor,
                        text_color: designTextColor,
                        button_color: designButtonColor,
                        text: dynamicTextStyles.text,
                        subject: dynamicTextStyles.subject,
                        company_name: dynamicTextStyles.company,
                    },
                }
            }

            formData.append('payload', JSON.stringify(newObject))
            formData.append('logo', logoBlob)

            saveEditDynamicTextCode({ data: formData, id: newObject.id }).then((res) => {
                if ('error' in res) {
                    errorToast('edit')
                    setIsGenerating(false)
                } else {
                    handleDiscardCreation()
                    navigate('/dashboard')
                    editedToast()
                }
            })
        }
    }

    const handleDiscardCreation = () => {
        setEditCodeData({})
        setCodeName('')
        setDynamicWebsiteStyles({
            website: 'https://'
        })
        setDynamicPdfStyles({
            files: [],
            logo: null,
            image: null,
            company: '',
            title: '',
            description: '',
            website: '',
            button: '',
        })
        setDynamicMP3Styles({
            file: '',
            logo: null,
            title: '',
            description: '',
            website: '',
            button: '',
            allowDownload: false,
            redirect: '',
        })
        setDynamicVideoStyles({
            files: [],
            logo: null,
            link: '',
            showDirectly: false,
            highlightVideo: false,
            autoplayVideo: false,
            company: '',
            title: '',
            description: '',
            button: '',
            website: '',
            redirect: '',
        })
        setDynamicImageStyles({
            files: [],
            title: '',
            description: '',
            website: '',
            button: '',
            redirect: '',
            deleted: [],
        })
        setDynamicWifiStyles({
            network_name: 'WEP',
            network_type: '',
            password: '',
            hidden: '',
            logo: null,
            cover: null,
        })
        setDynamicTextStyles({
            text: '',
            subject: '',
            company: '',
            logo: null,
        })
        setIsGenerating(false)
        setEditMobileStep(1)
        resetDesignStyles()
    }

    const resetDesignStyles = () => {
        setForegroundColor('#000000')
        setBackgroundColor('#ffffff')
        setImage('')
        setFrame(1)
        setScanText('')
        setScanFont('Sans-Serif')
        setScanColor('#000000')
        setDesignPrimaryColor('')
        setDesignSecondaryColor('')
        setDesignTextColor('')
        setDesignButtonColor('')
    }

    const resetMobileQrCodeStyles = () => {
        setForegroundColor('#000000')
        setBackgroundColor('#ffffff')
        setImage('')
        setFrame(1)
        setScanText('')
        setScanFont('Sans-Serif')
        setScanColor('#000000')
    }

    const resetMobileLandingStyles = () => {
        setDesignPrimaryColor('')
        setDesignSecondaryColor('')
        setDesignTextColor('')
        setDesignButtonColor('')
    }

    useEffect(() => {
        const palette = COLOR_PALETTE_LIST.find((el) =>
            el?.primary_color?.toLowerCase() === designPrimaryColor?.toLowerCase() &&
            el?.secondary_color?.toLowerCase() === designSecondaryColor?.toLowerCase() &&
            el?.text_color?.toLowerCase() === designTextColor?.toLowerCase() &&
            el?.button_color?.toLowerCase() === designButtonColor?.toLowerCase())

        if (!palette) {
            setSelectedColorPalette(null)
        } else {
            setSelectedColorPalette(palette.id)
        }
    }, [designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor])

    useEffect(() => {
        if (editCodeData) {
            setStaticValue(`${window.location.origin}/code/${editCodeData?.public_id}`)
            setImage(editCodeData?.styles?.icon)
            setFrame(editCodeData?.styles?.frame)
            setScanFont(editCodeData?.styles?.scanFont)
            setScanText(editCodeData?.styles?.scanText)
            setScanColor(editCodeData?.styles?.scanColor)
            setBackgroundColor(editCodeData?.styles?.background)
            setForegroundColor(editCodeData?.styles?.foreground)
            setDesignPrimaryColor(editCodeData?.data?.content?.primary_color)
            setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color)
            setDesignButtonColor(editCodeData?.data?.content?.button_color)
            setDesignTextColor(editCodeData?.data?.content?.text_color)
            setType(editCodeData?.data?.kind)

            const type = editCodeData?.data?.kind

            if (type === 'website_url') {
                setDynamicWebsiteStyles({
                    ...dynamicWebsiteStyles,
                    website: editCodeData?.data?.content?.website_url,
                })
            } else if (type === 'pdf') {
                setDynamicPdfStyles({
                    ...dynamicPdfStyles,
                    company: editCodeData?.data?.content?.company_name,
                    title: editCodeData?.data?.content?.title,
                    description: editCodeData?.data?.content?.description,
                    website: editCodeData?.data?.content?.website,
                    button: editCodeData?.data?.content?.button_text,
                    logo: editCodeData?.data?.additional_images?.logo,
                    image: editCodeData?.data?.additional_images?.cover,
                    files: editCodeData?.data?.files,
                })
                setDesignPrimaryColor(editCodeData?.data?.content?.primary_color ? editCodeData?.data?.content?.primary_color : '#E2E8F0')
                setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color ? editCodeData?.data?.content?.secondary_color : '#FFFFFF')
                setDesignTextColor(editCodeData?.data?.content?.text_color ? editCodeData?.data?.content?.text_color : '#334155')
                setDesignButtonColor(editCodeData?.data?.content?.button_color ? editCodeData?.data?.content?.button_color : '#7D8898')
            } else if (type === 'mp3') {
                setDynamicMP3Styles({
                    ...dynamicMP3Styles,
                    title: editCodeData?.data?.content?.title,
                    description: editCodeData?.data?.content?.description,
                    website: editCodeData?.data?.content?.website,
                    button: editCodeData?.data?.content?.button_text,
                    redirect: editCodeData?.data?.content?.button_redirect_link,
                    file: editCodeData?.data?.default_file.file,
                    logo: editCodeData?.data?.default_image.image,
                })
                setDesignPrimaryColor(editCodeData?.data?.content?.primary_color ? editCodeData?.data?.content?.primary_color : '#E2E8F0')
                setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color ? editCodeData?.data?.content?.secondary_color : '#FFFFFF')
                setDesignTextColor(editCodeData?.data?.content?.text_color ? editCodeData?.data?.content?.text_color : '#334155')
                setDesignButtonColor(editCodeData?.data?.content?.button_color ? editCodeData?.data?.content?.button_color : '#7D8898')
            } else if (type === 'video') {
                setDynamicVideoStyles({
                    ...dynamicVideoStyles,
                    files: editCodeData?.data?.files,
                    link: editCodeData?.data?.content?.video_url,
                    company: editCodeData?.data?.content?.company_name,
                    title: editCodeData?.data?.content?.title,
                    description: editCodeData?.data?.content?.description,
                    button: editCodeData?.data?.content?.button_text,
                    redirect: editCodeData?.data?.content?.button_redirect_link,
                    website: editCodeData?.data?.content?.website,
                    logo: editCodeData?.data?.additional_images?.logo,
                })
                setDesignPrimaryColor(editCodeData?.data?.content?.primary_color ? editCodeData?.data?.content?.primary_color : '#E2E8F0')
                setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color ? editCodeData?.data?.content?.secondary_color : '#FFFFFF')
                setDesignTextColor(editCodeData?.data?.content?.text_color ? editCodeData?.data?.content?.text_color : '#334155')
                setDesignButtonColor(editCodeData?.data?.content?.button_color ? editCodeData?.data?.content?.button_color : '#7D8898')
            } else if (type === 'images') {
                setDynamicImageStyles({
                    ...dynamicImageStyles,
                    files: editCodeData?.data?.images,
                    title: editCodeData?.data?.content?.title,
                    description: editCodeData?.data?.content?.description,
                    button: editCodeData?.data?.content?.button_text,
                    redirect: editCodeData?.data?.content?.button_redirect_link,
                    website: editCodeData?.data?.content?.website_url,
                })
                setDesignPrimaryColor(editCodeData?.data?.content?.primary_color ? editCodeData?.data?.content?.primary_color : '#FFFFFF')
                setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color ? editCodeData?.data?.content?.secondary_color : '#E2E8F0')
                setDesignTextColor(editCodeData?.data?.content?.text_color ? editCodeData?.data?.content?.text_color : '#334155')
                setDesignButtonColor(editCodeData?.data?.content?.button_color ? editCodeData?.data?.content?.button_color : '#7D8898')
            } else if (type === 'wifi') {
                setDynamicWifiStyles({
                    ...dynamicWifiStyles,
                    network_name: editCodeData?.data?.content?.network_name,
                    network_type: editCodeData?.data?.content?.type,
                    password: editCodeData?.data?.content?.password,
                    hidden: editCodeData?.data?.content?.hidden,
                    logo: editCodeData?.data?.additional_images?.logo,
                    cover: editCodeData?.data?.additional_images?.cover,
                })
                setDesignPrimaryColor(editCodeData?.data?.content?.primary_color ? editCodeData?.data?.content?.primary_color : '#FFFFFF')
                setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color ? editCodeData?.data?.content?.secondary_color : '#FFFFFF')
                setDesignTextColor(editCodeData?.data?.content?.text_color ? editCodeData?.data?.content?.text_color : '#334155')
                setDesignButtonColor(editCodeData?.data?.content?.button_color ? editCodeData?.data?.content?.button_color : '#FFFFFF')
            } else if (type === 'text') {
                setDynamicTextStyles({
                    ...dynamicTextStyles,
                    text: editCodeData?.data?.content?.text,
                    subject: editCodeData?.data?.content?.subject,
                    company: editCodeData?.data?.content?.company_name,
                    logo: editCodeData?.data?.additional_images?.logo,
                })
                setDesignPrimaryColor(editCodeData?.data?.content?.primary_color ? editCodeData?.data?.content?.primary_color : '#E2E8F0')
                setDesignSecondaryColor(editCodeData?.data?.content?.secondary_color ? editCodeData?.data?.content?.secondary_color : '#FFFFFF')
                setDesignTextColor(editCodeData?.data?.content?.text_color ? editCodeData?.data?.content?.text_color : '#334155')
                setDesignButtonColor(editCodeData?.data?.content?.button_color ? editCodeData?.data?.content?.button_color : '#7D8898')
            }
        }
    }, [editCodeData])

    useEffect(() => {
        const borderWidth = user ? 4 : 8

        if (frame === 1) {
            setCanvasStyles({})
        } else if (frame === 2) {
            setCanvasStyles({
                border: `${borderWidth}px solid black`,
            })
        } else if (frame === 3) {
            setCanvasStyles({
                border: `${borderWidth}px solid black`,
                borderRadius: '25px'
            })
        } else if (frame === 4) {
            if (user) {
                setCanvasStyles({
                    border: `${borderWidth}px solid black`,
                    width: '225px',
                    height: '225px',
                    borderRadius: '100%',
                    padding: scanText ? '25px' : '35px',
                    fontSize: '20px',
                })
            } else {
                setCanvasStyles({
                    border: `${borderWidth}px solid black`,
                    width: '306px',
                    height: '306px',
                    borderRadius: '100%',
                    padding: scanText ? '40px' : '50px',
                })
            }
        }
    }, [frame, scanText, user])

    const value = {
        dynamicWebsiteStyles, setDynamicWebsiteStyles, isGenerating,
        setIsGenerating, editCodeData, setEditCodeData, handleSaveEditCode,
        codeName, setCodeName, handleDiscardCreation, dynamicPdfStyles,
        setDynamicPdfStyles, dynamicMP3Styles, setDynamicMP3Styles,
        dynamicVideoStyles, setDynamicVideoStyles, dynamicImageStyles,
        setDynamicImageStyles, dynamicWifiStyles, setDynamicWifiStyles,
        dynamicTextStyles, setDynamicTextStyles, staticValue, backgroundColor,
        foregroundColor, imageSettings, canvasCreationRef, expandEditQrCode,
        setExpandEditQrCode, handleChangeForegroundColor, handleChangeBackgroundColor,
        handleForegroundColor, handleBackgroundColor, setForegroundColor,
        setBackgroundColor, selectForegroundColor, selectBackgroundColor,
        image, setImage, frame, setFrame, scanText, setScanText, scanColor,
        setScanColor, setSelectScanColor, selectScanColor, scanColorRef,
        scanFont, setScanFont, setSelectForegroundColor, setSelectBackgroundColor,
        canvasStyles, resetDesignStyles, expandEditLanding, setExpandEditLanding,
        designPrimaryColor, setDesignPrimaryColor, designSecondaryColor,
        setDesignSecondaryColor, designTextColor, setDesignTextColor, designButtonColor,
        setDesignButtonColor, designPrimaryColorRef, designSecondaryColorRef, designTextColorRef,
        designButtonColorRef, selectDesignPrimaryColor, setSelectDesignPrimaryColor,
        selectDesignSecondaryColor, setSelectDesignSecondaryColor,
        selectDesignTextColor, setSelectDesignTextColor, resetMobileLandingStyles,
        selectDesignButtonColor, setSelectDesignButtonColor, type, setType, resetMobileQrCodeStyles,
        editMobileStep, setEditMobileStep, selectedColorPalette, setSelectedColorPalette, handleSelectPalette
    };

    return (
        <EditQrCodeContext.Provider value={value}>
            {children}
        </EditQrCodeContext.Provider>
    );
};
