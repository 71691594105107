import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import React from 'react'

import { TRANSLATIONS_PRICES_FOR_CHANGE_PLAN } from '../../helpers/translation-constants'
import i18n from '../../i18n/config'
import {
    useChangeUserPlanMutation,
    useSendEmailSendgridCancelPlanMutation,
    useUpdateDuringCancelationMutation,
} from '../../api/api'

import Close from '../../assets/icons/settings/close.svg'

const CancelPlanConfirmationModal = ({ show, onClose, cancelPlan, planName }) => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const offer50PercentsDiscount = planName === 'Unlimited Plan'
        || planName === '14-Days Limited Access'
        || planName === '14-Days Full Access'

    const [sendEmailSendgridCancelPlan] = useSendEmailSendgridCancelPlanMutation()
    const [updateDuringCancelation] = useUpdateDuringCancelationMutation()
    const [changeUserPlan] = useChangeUserPlanMutation()

    const handleUpgradeToYearlyWithDiscount = () => {
        const priceData = TRANSLATIONS_PRICES_FOR_CHANGE_PLAN[i18n.language]?.['Yearly Plan']
            ?? TRANSLATIONS_PRICES_FOR_CHANGE_PLAN['en']?.['Yearly Plan']

        changeUserPlan({
            ...priceData
        })
        updateDuringCancelation()
        onClose()
    }

    const handleCancelPlan = () => {
        cancelPlan()
        onClose()
        sendEmailSendgridCancelPlan({
            plan_name: planName,
        })
        window.gtag('event', 'user_cancel_plan_from_settings', {
            user: user.uid,
            payment_method: subscription?.payment_method === 'paypal' ? 'paypal' : 'stripe',
        })
    }

    return (
        <Modal
            show={show}
            size={offer50PercentsDiscount ? "lg" : "md"}
            popup="false"
            centered
            position='center'
            onClose={onClose}
            className='modal-please-signup modal-cancel-plan'
        >
            <Modal.Body className={offer50PercentsDiscount ? 'confirm-archive-modal-body max-w-[680px] gap-6' : 'confirm-archive-modal-body max-w-[420px]'}>
                {offer50PercentsDiscount ? (
                    <>
                        <h5 className='confrim-modal-title relative w-full'>
                            {t("manageYourSubscription")}
                            <img
                                onClick={onClose}
                                className="absolute right-0 top-1/2 translate-y-[-50%] cursor-pointer"
                                src={Close}
                                alt=""
                            />
                        </h5>
                        <div className='grid grid-cols-2 gap-6'>
                            <div className="cancel-plan-upgrade-to-yearly-block">
                                <div className="flex flex-col gap-[12px]">
                                    <h6 className="text-white text-base font-semibold text-center">
                                        {t("upgradeToYearlyPlan")}
                                    </h6>
                                    <h5 className="font-bold text-white text-[33px] leading-[40px] tracking-[-0.02em] text-center">
                                        {t("save50Percents")}
                                    </h5>
                                    <p className="text-base text-white text-center">
                                        {t("maintainAccessToAllOfYourCodesAndAllFeatures")}
                                    </p>
                                </div>
                                <button onClick={handleUpgradeToYearlyWithDiscount} className="h-[44px] rounded-lg bg-white text-[14px] leading-[17px] font-semibold text-[#141315] hover:bg-[#f7fafe] transition-colors duration-300">
                                    {t("upgradeToHalfYearlyPlan")}
                                </button>
                            </div>
                            <div className='cancel-plan-confirm-block'>
                                <div className="flex flex-col gap-[12px] h-[136px] justify-center">
                                    <h6 className="text-base font-semibold text-[#141315] text-center">
                                        {t("cancelSubscription")}
                                    </h6>
                                    <p className="text-base text-[#141315] text-center">
                                        {t("onceCancelledYouWillLoseTheAbilityToCreateAndManageDynamicCodes")}
                                    </p>
                                </div>
                                <button onClick={handleCancelPlan} className="h-[44px] rounded-lg border-[1px] border-solid border-[#141315] text-[14px] leading-[17px] font-semibold text-[#141315] hover:border-[#E2E8F0] transition-colors duration-300">
                                    {t("cancelSubscription")}
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h5 className='confrim-modal-title w-full max-w-[280px]'>{t("areYouSureYouWantToCancelYourPlan")}</h5>
                        <div className='confirm-modal-actions-block'>
                            <button onClick={onClose} className='confirm-modal-action-cancel'>{t("keepPlan")}</button>
                            <button onClick={handleCancelPlan} className='confirm-modal-action-delete'>{t("yesCancelPlan")}</button>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal >
    )
}

export default CancelPlanConfirmationModal