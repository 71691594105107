import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { IoMdMore } from 'react-icons/io'

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext'

import CreationContentDescription from '../../common/CreationContentDescription/CreationContentDescription'
import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle'
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock'
import UploadBlock from '../../common/UploadBlock/UploadBlock'
import EditFileBlock from '../../EditFileBlock/EditFileBlock'
import CodeNameInput from '../CodeNameInput/CodeNameInput'

import editMp3IconSvg from '../../../assets/icons/edit-mp3-icon.svg'

const UserMp3 = ({ triggerValidation, setIsValid }) => {
    const [filesErrors, setFilesErrors] = useState([])

    const uploadFileRef = useRef(null)

    const { t } = useTranslation()

    const {
        dynamicMP3Styles, setDynamicMP3Styles
    } = useUserCodeCreationContext()

    const { register, trigger, formState: { errors, isValid } } = useForm({
        mode: 'onBlur'
    });

    const handleChangeLogoFile = (file) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'Logo')
        })

        setDynamicMP3Styles({
            ...dynamicMP3Styles,
            logo: file,
        })
    }

    const handleChangeFile = (file) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'MP3')
        })

        setDynamicMP3Styles({
            ...dynamicMP3Styles,
            file: file[0],
        })
    }

    const handleChangeInput = (name, value) => {
        setDynamicMP3Styles({
            ...dynamicMP3Styles,
            [name]: value,
        })
    }

    const handleChangeClick = () => {
        uploadFileRef.current.click()
    }

    useEffect(() => {
        if (setIsValid) {
            setIsValid(isValid)
        }
    }, [isValid, setIsValid])

    useEffect(() => {
        if (triggerValidation) {

            const triggerFunction = async () => {
                let result = true;

                result = await trigger()

                if (!dynamicMP3Styles.file) {
                    setFilesErrors((prev) => {
                        return [...prev, 'MP3']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'MP3')
                    })
                }

                if (!dynamicMP3Styles.logo) {
                    setFilesErrors((prev) => {
                        return [...prev, 'Logo']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'Logo')
                    })
                }

                return result
            }

            triggerValidation(triggerFunction)
        }
    }, [triggerValidation, trigger, dynamicMP3Styles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            <CreationContentTitle title={"MP3"} withStep={false} />
            <CreationContentDescription customStyles={{ marginTop: '-12px', marginBottom: '-8px' }} description={t("uploadOneMp3File")} />
            <CodeNameInput />
            {dynamicMP3Styles.file ? (
                <div className='edit-file-block-wrapper'>
                    <div className='edit-file-left-side-block'>
                        <div className='edit-file-left-side-img-block'>
                            <img className='edit-file-image-mobile' src={editMp3IconSvg} alt="" />
                        </div>
                        <div className='edit-file-left-side-info-block'>
                            <span className='edit-file-left-side-info-name'>{dynamicMP3Styles?.file?.name}</span>
                            <span className='edit-file-left-side-info-size'>{(dynamicMP3Styles?.file?.size / 1024).toFixed(2)} KB</span>
                        </div>
                    </div>
                    <Dropdown className='dropdown-edit-list'>
                        <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                            <IoMdMore className='cursor-pointer' size={25} fill='#334155' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleChangeClick} className='bootstrap-default-item bootstrap-bold-item'>
                                {t("change")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className='edit-file-right-side-block'>
                        <button onClick={handleChangeClick} className='edit-file-right-side-change-btn'>{t("change")}</button>
                    </div>
                    <input accept="audio/*" onChange={(e) => handleChangeFile(e.target.files)} ref={uploadFileRef} multiple={false} type="file" className='hidden' />
                </div>
            ) : (
                <UploadBlock
                    title={t("uploadMp3")}
                    limit={'25MB'}
                    acceptList={'.mp3,audio/*'}
                    multiple={false}
                    onChangeFiles={handleChangeFile}
                    uploadedFiles={dynamicMP3Styles.file ? 1 : 0}
                    isError={filesErrors.includes('MP3')}
                />
            )}
            <span className='dynamic-form-underline'></span>
            <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("title")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.title}
                        placeholder={t("exNameOfSongOrAudioFile")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterMp3Title")}</p>}
                </div>
                {dynamicMP3Styles.logo ? (
                    <EditFileBlock
                        file={dynamicMP3Styles.logo}
                        title={dynamicMP3Styles?.logo?.name ? dynamicMP3Styles?.logo?.name : t("logoFile")}
                        onFileChanged={handleChangeLogoFile}
                    />
                ) : (
                    <UploadLogoBlock
                        title={t("addCover")}
                        limit={"5MB"}
                        acceptList={"image/*"}
                        multiple={false}
                        onChangeFiles={handleChangeLogoFile}
                        uploadedFiles={dynamicMP3Styles.logo ? 1 : 0}
                        isError={filesErrors.includes('Logo')}
                        errorText={t("logoFileMustBeUploaded")}
                    />
                )}
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.description}
                        placeholder={t("exNameOfArtistAndAlbum")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.button}
                        placeholder={t("exPlayNow")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("buttonRedirectUrl")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.redirect}
                        placeholder='E.g. https://www.mymusic.com/playlist'
                        className={errors.redirect ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("redirect", {
                            onChange: (e) => handleChangeInput('redirect', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.redirect && <p className='error-text'>{t("exMyMusicUrl")}</p>}
                </div>
            </div>
        </form>
    )
}

export default UserMp3