import React from 'react'
import { useTranslation } from 'react-i18next'

const TextPreview = ({ data, creationPreview }) => {
    const { t } = useTranslation()

    return (
        <div className='text-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
            <div className='text-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                {data.logo ? (
                    <div className='text-preview-logo-block'>
                        <img className='text-preview-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                    </div>
                ) : creationPreview ? (
                    <div className='pdf-logo-empty'></div>
                ) : null}
                {(data.company || creationPreview) && (
                    <span style={{ color: data.designTextColor, wordBreak: 'break-all' }} className='text-preview-company'>
                        {data.company ? data.company : t("company")}
                    </span>
                )}
                {(data.subject || creationPreview) && (
                    <span style={{ color: data.designTextColor, wordBreak: 'break-all' }} className='text-preview-subject'>
                        {data.subject ? data.subject : t("subject")}
                    </span>
                )}
                <span className='text-preview-title' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                    {data.text ? data.text : t("message")}
                </span>
            </div>
            <div className='text-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>

            </div>
        </div >
    )
}

export default TextPreview